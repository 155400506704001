.modalSpotlight {
  background: transparent !important;
}
.modalBodySpotlight {
  width: 45vw !important;
  padding: 0 !important;
  margin: 0 !important;
  padding-left: 25px !important;
  padding-right: 35px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  margin-bottom: 10% !important;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: #404040;
  border-radius: 9px !important;
  z-index: 9999 !important;
  opacity: 0.97 !important;
}

.modalBodySpotlight img {
  width: 20px;
  height: 20px;
  margin-right: 15px;
  cursor: pointer;
  user-select: none;
}

.spotlightInput {
  width: 100% !important;
  height: 50px !important;
  background-color: transparent;
  color: white;
  font-size: 1.8rem;
  font-family: "Gilroy Regular";
  user-select: none;
  &:focus {
    color: white;
  }
  &::placeholder {
    color: #9e9e9e;
  }
}

.modalBodySpotlight p {
  font-size: 1.2rem;
  color: white;
  cursor: pointer;
  user-select: none;
}
