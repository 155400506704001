.checkboxContainer {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  /* background-color: #f8f9ff; */
  border-style: solid none;
  border-width: 1px;
  border-color: #dcdcdc;
  &:hover {
    background-color: #fbfbfe;
  }
}
.borderWidthAndColor {
  background-color: #f8f9ff;
  border-width: 1.5px;
  border-color: #4256d0;
}
.defaultTemplateContainer {
  background-color: #effff9; /* #fff8e8; */
}
.mainTilesContainer {
  min-height: 97px;
  padding: 3%;
  align-items: center;
  font-family: Arial, Helvetica, sans-serif;
  overflow: hidden;
  width: 100%;
  cursor: pointer;
}
.spaceAndFlex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
}
.mhr-description {
  font-family: "Gilroy Regular";
  font-size: var(--body); /*12px;*/
}
.imageContainer {
  font-family: "Gilroy Semi-Bold";
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.imageContainer p {
  /* height: 100%; */
  font-size: var(--body2);
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.userImage {
  height: 18px;
  margin-right: 5px;
}
.borderContainer {
  font-family: "Gilroy Semi-Bold";
  border: 1.5px solid #c9c9c9;
  border-radius: 20px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 7px;
  padding-right: 7px;
  margin-right: 10px;
  font-size: var(--body); /*12px;*/
}
.copyImg {
  height: 26px;
  cursor: pointer;
}
.editDeleteContainer {
  font-family: "Gilroy Semi-Bold";
  display: flex;
  flex-direction: row;
  font-size: var(--body); /*12px;*/
  justify-content: center;
  align-items: start;
  padding-left: 20px;
}
.actionButtons {
  border: none;
  background-color: transparent;
  cursor: pointer;
  &:hover {
    color: #969696;
  }
}
.link {
  display: flex;
  flex-direction: row;
  width: fit-content;
}
.link img {
  height: 14px;
}
.link p {
  font-family: "Gilroy Regular";
  padding-left: 5px;
  font-size: var(--body3); /*10px;*/
  &:hover {
    color: #969696;
  }
}
.checkboxContainer {
  padding-left: 1vw;
  padding-right: 1vw;
}
.rounded-checkbox {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid black;
  appearance: none;
  outline: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}
.rounded-checkbox:checked {
  appearance: auto;
  clip-path: circle(50% at 50% 50%);
  background-color: blue;
}
.mhr-title {
  font-family: "Gilroy Semi-Bold";
  font-size: var(--heading2); /*16px;*/
}
.defaultTemplate {
  display: flex;
  flex-direction: row;
  margin-right: 8px;
  align-items: end;
}
.defaultTemplate p {
  font-family: "Gilroy Semi-Bold";
  font-size: var(--body4); /*9px;*/
}
.defaultTemplate div {
  background-color: #22bc81; /*#ffc120;*/
  border: none;
  border-radius: 100%;
  width: 16px;
  height: 16px;
  margin-left: 5px;
  margin-right: 7px;
}
.checkButton-checked {
  width: 3rem;
  height: 3rem;
  background-color: #4256d0;
  border: 2px solid #15268f;
  border-radius: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  overflow: hidden;
}
.checkButton-checked img {
  width: 2rem;
  height: 2remß;
}
.checkButton-unchecked {
  width: 3rem;
  height: 3rem;
  background-color: white;
  border: 2px solid #969696;
  border-radius: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  overflow: hidden;
}
.actionButton2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1.5px solid;
  border-radius: 20px;
  border-color: #c9c9c9;
  cursor: pointer;
  background-color: white;
}
.actionButton2 div {
  margin-left: 20px;
  margin-right: 7px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #22bc81;
  border: none;
  border-radius: 100%;
  width: 1rem;
  height: 1rem;
}
.actionButton2 p {
  font-family: "Gilroy Regular";
  margin-right: 20px;
  font-size: var(--body2);
}
