.columns-container{
    border-top: 2px solid #c5c2c2;
    background-color: #f1f1f1;
    min-height: 1000px;
    height: 1000px;
    overflow-y: scroll;

}
.columns-page{
    width: 80%;
    margin: auto;
    padding: 100px;
    padding-bottom: 400px;

}
.check-list{
    list-style: none;
    padding: unset;
    margin: unset;
    padding-bottom: 30px;
}
.check-list-item{
    margin-left: 20px;
}
.check-list-item > label{
    margin-left: 20px;
    font-size: 12px;
}
.check-list-container{
    /* border-bottom: 1px solid white; */
}
.check-list-heading{
    border-bottom: 1px solid white;
    cursor: pointer;
    background-color: #586173;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 20px;
    color: white;
}