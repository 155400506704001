.sequence-modal-subheading {
  font-family: "Gilroy Semi-Bold";
  font-size: var(--subheading2); /*1rem;*/
  padding-bottom: 0.5vh;
}
.sequence-modal-title {
  font-family: "Gilroy Semi-Bold";
  font-size: var(--heading); /*1.8rem;*/
}
.sequence-modal-description {
  font-family: "Gilroy Regular";
  font-size: var(--subheading2); /*1.4rem;*/
}
.sequence-modal-line {
  background-color: #ccc;
  height: 1px;
  width: 100%;
  margin-top: 1vh;
  margin-bottom: 1vh;
}
.sequence-modal-line-upper {
  background-color: #ccc;
  height: 1px;
  width: 100%;
  margin-top: 1vh;
}
.sequence-modal-line-lower {
  background-color: #ccc;
  height: 1px;
  width: 100%;
  margin-bottom: 1vh;
}
.sequence-modal-inner-container {
  width: 100%;
  height: 85px;
  max-height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}
.sequence-modal-inner-inner-container1 {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}
.sequence-modal-vline {
  width: 1px;
  /* flex: 1; */
  height: 100%;
  background-color: #ccc;
}
.sequence-modal-inner-inner-container2 {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding-left: 1.5vw;
}
.sequence-inner-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  width: 85%;
}
.sequence-inner-container p {
  font-size: var(--body); /*1.4rem;*/
  max-lines: 1;
  text-align: start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sequence-inner-container img {
  height: 14px;
  margin-right: 5px;
}
.sequence-buttonsContainerModal {
  width: 30%;
  color: white;
  margin-left: 3vw;
  margin-right: 3vw;
  /* margin-top: 1vh; */
  margin-bottom: 3vh;
}
.sequence-close {
  background-color: #454545;
  width: 100%;
  height: 38px;
  color: white;
  font-family: "Gilroy Regular";
  font-size: 16px;
  border: none;
}
