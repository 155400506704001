.harvest-action-button {
	padding: 0;
	margin-left: 5px;
	margin-right: 5px;
	min-height: 20px;
	padding-top: 5px;
	padding-bottom: 4px;
	padding-left: 15px;
	padding-right: 15px;
	border-radius: 5px;
	font-size: 1.4rem;
	background-color: transparent;
	font-family: "Gilroy Semi-Bold";
	border: 2px solid #333;
	color: "black";
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
.havrestSubheading {
	margin-bottom: 40px;
	font-size: 1.5rem;
}
.harvestModalBody {
	width: 45%;
}
.customHarvestPopup {
	padding: 30px;
	padding-top: 40px;
	padding-bottom: 25px;
}
.customHarvestPopup h1 {
	font-size: 2.2rem;
}
.customHarvestPopup span {
	font-size: 1.5rem;
}
.customHarvestPopup button {
	font-size: 1.6rem;
}
.harvestModalBody h1 {
	font-size: 2.8rem;
}
.harvestModalBody span {
	font-size: 1.5rem;
}
.harvestModalBody button {
	font-size: 1.6rem;
}
