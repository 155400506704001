/** WARNING - USE AT OWN RISK */
/** IT IS AGAINST APPLE'S POLICY TO USE SF PRO FOR ANYTHING OTHER THAN iOS/tvOS/macOS/watchOS DESIGN & DEVELOPMENT */
/** https://sf.abarba.me/font.css */
/** https://sf.abarba.me/LICENSE.pdf */

/** 1. Copy/import this file into your main css/scss file */
/** 2. Change css font-family: to "SF Text", "SF Display" or "SF Mono" */
/** 3. Apply font-weight or italic to html elements */

/** THANK YOU */
/** I host these fonts on Cloudfront with SSL in all AWS regions for the best performance and reliability */
/** If you'd like to help offset costs, I'd very much appreciate a donation via Lisk https://lisk.io */
/** Address: 14987768355736502769L */
/** Delegate: andrew */

/*---------------------------------------------------------------------------*
 * SF UI Display
 *---------------------------------------------------------------------------*/

/** SF PRo SEmi Bold */
@font-face {
	font-family: 'SF Pro Display Semibold';
	/* font-weight: 900; */
	src: url(../src/sf-pro/SF-Pro-Display-Semibold.otf);
}

/** Regular */
@font-face {
	font-family: 'SF Pro Display Regular';
	/* font-weight: 700; */
	src: url(../src/sf-pro/SF-Pro-Text-Regular.otf);
}

/** Medium */
@font-face {
	font-family: 'SF Pro Display Medium';
	/* font-weight: 800; */
	src: url(../src/sf-pro/SF-Pro-Text-Regular.otf);
}
@font-face {
	font-family: 'SF Pro Display Light';
	/* font-weight: 600; */
	src: url(../src/sf-pro/SF-Pro-Display-Light.otf);
}
@font-face {
	font-family: 'SF Pro Text Medium';
	/* font-weight: 800; */
	src: url(../src/sf-pro/SF-Pro-Text-Medium.otf);
}
@font-face {
	font-family: 'SF Pro Text Bold';
	/* font-weight: 800; */
	src: url(../src/sf-pro/SF-Pro-Text-Bold.otf);
}
@font-face {
	font-family: 'SF Pro Text Regular';
	/* font-weight: 800; */
	src: url(../src/sf-pro/SF-Pro-Text-Regular.otf);
}
