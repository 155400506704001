.sequences-dropdown2 {
  font-family: "Gilroy Regular";
  user-select: none;
  /* font-size: 12px; */
  font-size: var(--body); /*1.4rem;*/
  position: relative;
  /* margin-left: 1%; */
  height: 100%;
  width: 100%;
  /* min-height: 20px; */
}
.SequencesDropdownMainContainer2 {
  font-family: "GIlroy Regular";
  /* font-size: 12px; */
  font-size: var(--body); /*1.4rem;*/
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid #c8c8c8; */
  /* padding-left: 3%; */
  background-color: white;
}
.SequencesDropdownMainContainerNotActive2 {
  cursor: not-allowed;
}
.SequencesDropdownMainContainerActive2 {
  &:hover {
    background-color: rgb(242, 241, 241);
    cursor: pointer;
  }
}
.whiteBackground2 {
  background-color: white;
}
.itemsContainer2 {
  padding-right: 3%;
  width: 100%;
  /* flex: 1; */
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.itemsContainer2 p {
  font-family: "Gilroy Semi-Bold";
  max-lines: 1;
  text-align: start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 3%;
}
.arrowsContainer2 {
  width: 15%;
  height: 100%;
  border-left: 3px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.arrowsContainer2 img {
  height: 6.15px;
}

.sequence-dropdown-menu3 {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 150px;
  border: 1px solid #ccc;
  background-color: #fff;
  overflow: hidden;
  overflow-y: auto;
  z-index: 9999909999999;
}
.sequence-dropdown-menu4 {
  position: absolute;
  bottom: 101%;
  left: 0;
  width: 100%;
  max-height: 150px;
  border: 1px solid #ccc;
  background-color: #fff;
  overflow: hidden;
  overflow-y: auto;
  z-index: 9999909999999;
}

.sequence-dropdown-item2 {
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #f2f2f2;
  }
}
.inputTextField2 {
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  background-color: transparent;
}
