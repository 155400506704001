.emailModal {
  font-family: "Gilroy Semi-Bold";
  margin: auto;
  border-radius: 10;
  height: 100vh;
  background: rgba(39, 38, 38, 0.5);
  /* top: 60%; */
  padding-top: 10vh;
}
.headingEmailModal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 3vw;
  margin-right: 3vw;
  margin-top: 5vh;
}

.headingEmailModal h2 {
  font-family: "Gilroy Semi-Bold";
  font-size: 26px;
}
.headingEmailModal img {
  width: 32px;
  height: 32px;
  cursor: pointer;
}
.emailModalLine {
  margin-top: 2vh;
  height: 1px;
  width: 100%;
  background-color: #9f9f9f;
}
.emailTemplateModalBody {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-self: center;
  margin-left: 3vw;
  margin-right: 3vw;
  margin-top: 3vh;
  margin-bottom: 2vh;
}

.emailTemplateModalBody h3 {
  font-size: var(--subheading); /*16px;*/
  margin-bottom: 10px;
}

.emailTemplateModalBody input textarea {
  border: none;
  border: 1px solid #d4d4d4;
}
.emailTemplateModalBody input {
  margin-bottom: 10px;
  height: 40px;
  font-size: var(--body); /*14px;*/
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 2vh;
}

.emailTemplateModalBody textarea {
  min-height: 116px;
  font-size: var(--body); /*12px;*/
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.buttonsContainerModal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 3vw;
  margin-right: 3vw;
  /* margin-top: 1vh; */
  margin-bottom: 3vh;
}
.buttonsContainerModal button {
  width: 49%;
  height: 38px;
  color: white;
  font-size: 16px;
  border: none;
}
.cancelButton {
  background-color: #454545;
}
.sendButton {
  background-color: #4256d0;
}
.fileDivPDF {
  border: 2px solid #ff5722;
}
.fileDivIMG {
  border: 2px solid #4256d0;
}
.fileDiv {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  /* border: 1px solid #ff5722; */
  border-radius: 20px;
  padding: 3px;
  width: fit-content;
  max-width: 100%;
  margin-bottom: 1.5vh;
  background-color: #f8f9ff;
}
.fileDivElipsePDF {
  border: 1px solid #ff5722;
  background-color: #ff5722;
}
.fileDivElipseIMG {
  border: 1px solid #4256d0;
  background-color: #4256d0;
}
.fileDivElipse {
  height: 24px;
  width: 24px;
  min-height: 24px;
  min-width: 24px;
  /* border: 1px solid #ff5722; */
  border-radius: 100%;
  /* background-color: #ff5722; */
  color: white;
  font-size: 8px;
  padding-top: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1px;
}
.fileDivText p {
  /* max-width: 40%; */
  max-lines: 1;
  text-align: start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: var(--body3);
}
.fileDivText {
  width: fit-content;
  /* max-width: 60%; */
  overflow: hidden;
  margin-left: 10px;
  margin-right: 10px;
}
.fileDiv img {
  width: 14.87px;
  height: 14.87px;
  margin-right: 5px;
  cursor: pointer;
}
.modalLoader {
  display: flex;
  flex-direction: row;
  width: 49%;
  height: 38px;
  justify-content: center;
  align-items: center;
}
