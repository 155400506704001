.generic-dropdown {
  font-family: "Gilroy Regular";
  font-size: 12px;
  width: 100%;
  position: relative;
}
.arrowContainer {
  padding: 2%;
  height: 100%;
  width: 8%;
  border-left: 1px solid #ccc;
}
.noBorder {
  border-left: none !important;
}
.yesBOrder {
  border-left: 1px solid #ccc;
}
.arrowContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.generic-dropdown-text {
  user-select: none;
  padding: 2%;
  width: 92%;
  height: 100%;
  max-height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.generic-dropdown-text p {
  max-lines: 1;
  text-align: start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.arrowContainer img {
  height: 6.15px;
}
.generic-dropdown-button {
  border: 1px solid #ccc;
  max-height: 40px;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.rounded-dropdown-border {
  border-radius: 4px;
}
.generic-dropdown-menu {
  width: 100%;
  max-height: 145px;
  border: 1px solid #ccc;
  background-color: #fff;
  overflow: hidden;
  overflow-y: auto;
  margin-bottom: 10px;
  z-index: 1;
  position: absolute;
}
.generic-dropdown-item {
  padding: 2%;
  cursor: pointer;
  &:hover {
    background-color: #f2f2f2;
  }
}
