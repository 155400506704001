.Sequence-details-main-container {
  /* overflow: hidden;
  overflow-y: auto; */
  width: 100%;
}
.Sequence-details-main-container p {
  font-size: 1.4rem;
}
.Sequence-details-main-container h3 {
  font-family: "Gilroy Semi-Bold";
  font-size: 2.2rem;
  padding-top: 5px;
}
.Sequence-details-main-container hr {
  background-color: black;
  height: 0.3px;
  margin-bottom: 20px;
}
.tile-outer-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  width: 100%;
  height: 190px;
  min-height: 150px;
  /* max-height: 150px; */
}
.number-container {
  width: 15%;
  height: 150px;
  min-height: 150px;
  /* max-height: 150px; */
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}
.number-container p {
  font-size: 14px;
  border: 1px solid #d9d9d9;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  text-align: center;
  padding-top: 3px;
}
.number-container-line2 {
  height: 100%;
  width: 1px;
  background-color: #d9d9d9;
}

.tile-container {
  width: 100%;
}
.tile-container hr {
  height: 0.3px;
  background-color: #eeeeee;
}
.description-container {
  min-height: 55px;
}
.tile-container h3 {
  font-size: 1.8rem;
}
.tile-container p {
  font-size: 1.5rem;
  text-overflow: ellipsis;
  max-lines: 5;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
}
.tile-container h5 {
  font-size: 1.6rem;
}
.creator-container {
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}
.creator-container div {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}
.creator-outer-border {
  border: 1.5px solid #d9d9d9;
  border-radius: 10px;
  margin-right: 20px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 8px;
  padding-right: 8px;
}
.creator-container img {
  height: 14px;
  margin-right: 5px;
}
.creator-container p {
  max-lines: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
}
.sequence-number-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin-bottom: 5px;
}
.sequence-number-container img {
  height: 17px;
  margin-right: 10px;
}
.sequence-number-container-p {
  font-size: 12px;
  font-family: "Gilroy Semi-Bold";
}
.Sequence-details-main-container button {
  background-color: #454545;
  color: white;
  height: 38px;
  width: 214px !important;
  margin-top: 40px;
}

.Sequence-details-main-container button div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.Sequence-details-main-container button img {
  height: 12px;
  width: 22px;
  margin-right: 10px;
}
.Sequence-details-main-container button p {
  padding-top: 2px;
  font-size: 17px;
}
