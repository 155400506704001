.date-dropdown {
  font-family: "Gilroy Regular";
  user-select: none;
  font-size: var(--body);
  position: relative;
  height: 100%;
}
.dateDropdownMainContainer {
  font-family: "GIlroy Regular";
  font-size: var(--body);
  width: 100%;
  height: 100%;
  min-height: 42px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #c8c8c8;
  padding-left: 3%;
  background-color: white;
}
.dateDropdownMainContainerNotActive {
  background-color: #f2f2f2;
  cursor: not-allowed;
}
.dateDropdownMainContainerActive {
  &:hover {
    background-color: rgb(242, 241, 241);
    cursor: pointer;
  }
}
.whiteBackground {
  background-color: white;
}
.itemsContainer {
  padding-right: 3%;
  width: 90%;
  overflow: hidden;
}
.itemsContainer p {
  font-family: "Gilroy Semi-Bold";
  max-lines: 1;
  text-align: start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.arrowsContainer {
  width: 15%;
  max-width: 50px;
  height: 100%;
  min-height: 40px;
  border-left: 1px solid #c8c8c8;
  display: flex;
  justify-content: center;
  align-items: center;
}
.arrowsContainer img {
  height: 6.15px;
}
.arrow-container-img-rotate {
  transition: 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  transform: rotate(180deg);
}
.arrow-container-img-rotate-back {
  transition: 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  transform: rotate(0deg);
}
.date-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid #ccc;
  border-bottom: 1.5px solid #ccc;
  /* background-color: #fff; */
  background-color: #fdfdfd;
  overflow: hidden;
  z-index: 1;
}

.date-dropdown-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  padding-left: 3%;
  padding-right: 3%;
  cursor: pointer;
  &:hover {
    background-color: #f2f2f2;
  }
}
.border-top {
  border-top: 1.5px solid #ccc;
  justify-content: flex-start !important;
}
.border-top img {
  height: 18px;
  margin-right: 5px;
  margin-bottom: 4px;
}
.inputTextField {
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  background-color: transparent;
}
