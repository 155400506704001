.modal-title {
	font-size: 1.5rem; /* Increase the font size of the title */
	color: #0056b3; /* A decent blue color */
}

.modal-body {
	font-size: 1.2rem; /* Slightly larger text inside the modal body */
}

.custom-checkbox .form-check-input {
	accent-color: #007bff; /* Custom blue color for the checkbox */
}

.custom-checkbox .form-check-label {
	padding-left: 0.5rem; /* Add some space after the checkbox */
	color: #333; /* Dark text color for better readability */
}

.custom-checkbox {
	margin-bottom: 0.75rem; /* Add space between each checkbox item */
}
