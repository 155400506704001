.dropdown-mhr {
  font-family: "Gilroy Regular";
  font-size: 14px;
  /* font-size: var(--body2); 1.2rem; */
  position: relative;
  width: fit-content;
  min-width: 150px;
  max-width: 300px;
  margin-left: 20px;
  /* background-color: #f6f6f6; */
  background-color: white;
  cursor: pointer;
}
.greyBackground {
  background-color: #f4f4f4;
  cursor: not-allowed;
}
.dropdown-mhr img {
  height: 6.15px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  transition: transform 0.3s ease;
}

.dropdown-button-mhr {
  padding-left: 10px;
  border: 1px solid #ccc;
  height: 41px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-right: 30px;
  &:hover {
    background-color: #f4f4f4;
  }
}
.dropdown-button-mhr-roundBorders {
  border-radius: 4px;
}

.dropdown-menu-mhr {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 190px;
  border: 1px solid #ccc;
  background-color: #fff;
  overflow: hidden;
  overflow-y: auto;
  z-index: 2;
}

.dropdown-item-mhr {
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #f2f2f2;
  }
}
.disable-sequence {
  background-color: #eeeeee;
}
