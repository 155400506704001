.sequenceTileMainContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}
.tileLine {
  height: 1px;
  width: 100%;
  background-color: #d4d4d4;
  /* margin-bottom: 3vh; */
}
.innerContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  padding-left: 1vw;
  padding-right: 1vw;
  padding-bottom: 2vh;
  padding-top: 3vh;
  cursor: pointer;
  background-color: rgb(251, 251, 251);
  &:hover {
    /* background-color: #f8f9ff;
    border-top: 1px solid #4256d0;
    border-bottom: 1px solid #4256d0; */
    /* background-color: #f8f8f8; */
    background-color: white;
  }
}
.innerInnerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.innerContainer h6 {
  font-family: "Gilroy Semi-Bold";
  /* font-size: 16px; */
  font-size: var(--heading2); /*1.8rem;*/
  margin-right: 10px;
  /* margin-bottom: 2.4vh; */
}
.innerContainer img {
  /* height: 5.5vh;
  max-height: 51px; */
  height: 61px;
}
.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  overflow: hidden;
  padding-right: 1vw;
}
.textContainer h3 {
  font-family: "Gilroy Semi-Bold";
  /* font-size: 16px; */
  font-size: var(--heading2); /*1.8rem;*/
}
.textContainer h4 {
  font-family: "Gilroy Light";
  /* font-size: 12px; */
  font-size: var(--subheading2); /*1.5rem;*/
}
.textContainer h5 {
  font-family: "Gilroy Light";
  font-size: var(--body3); /*1.2rem;*/
  color: #505050;
}
