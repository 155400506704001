.mhr-confirmModalContainer {
  font-family: "Gilroy Regular";
}
.mhr-descriptionContainer {
  padding: 25px;
}
.mhr-descriptionContainer h3 {
  font-size: 2rem;
  padding-bottom: 15px;
  font-family: "Gilroy Semi-Bold";
}
.mhr-descriptionContainer p {
  font-family: "Gilroy Light";
  font-size: 1.6rem;
}
.mhr-buttonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
  margin-right: 10px;
}
.mhr-buttonsContainer button {
  outline: none;
  background: #333;
  border: none;
  display: inline-block;
  padding: 5px 24px;
  color: #eee;
  margin-right: 12px;
  border-radius: 5px;
  font-size: 1.3rem;
  cursor: pointer;
}
.mhr-buttonsContainer2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-right: 10px;
}
.mhr-buttonsContainer2 button {
  outline: none;
  /* background: #333; */
  border: none;
  display: inline-block;
  padding: 5px 24px;
  color: #eee;
  margin-right: 12px;
  /* border-radius: 5px; */
  font-size: 1.5rem;

  cursor: pointer;
  width: 19.5rem;
  height: 3.8rem;
}
.mhr-buttonsContainer2-button1 {
  background-color: #454545;
  &:hover {
    background-color: #636363;
  }
}
.mhr-buttonsContainer2-button2 {
  background-color: #f2bd00;
  &:hover {
    background-color: #ffce1c;
  }
}
.mhr-loader-button {
  width: 100px;
  height: 3.8rem;
  margin-right: 12px;
  /* margin-top: 2px; */
  cursor: not-allowed;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
