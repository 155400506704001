.detailsPart {
  border: 2px solid #dcdcdc;
  min-height: 54vh;
  max-height: 60vh;
  overflow: hidden;
  /* width: 100%; */
}
.actionButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #f6f7ff;
  padding-top: 5vh;
  padding-bottom: 5vh;
  border-bottom: 1.3px solid #e6e6e6;
  margin-bottom: 6vh;
}

.actionButton img {
  height: 2.9rem;
  cursor: pointer;
  margin-right: 0.4vw;
}

.actionButton div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1.5px solid;
  border-radius: 20px;
  border-color: #c9c9c9;
  cursor: pointer;
  background-color: white;
}

.actionButton div div {
  margin-left: 20px;
  margin-right: 7px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #22bc81; /*#ffc120;*/
  border: none;
  border-radius: 100%;
  width: 1rem;
  height: 1rem;
}
.actionButton div p {
  font-family: "Gilroy Regular";
  margin-right: 20px;
  font-size: var(--body2); /* 12px; */
}

.details {
  padding-left: 2vw;
  padding-right: 2vw;
  padding-bottom: 2vh;
  max-height: 42vh;
  overflow: hidden;
  overflow-y: auto;
}
.details::-webkit-scrollbar {
  width: 5px;
}
.details::-webkit-scrollbar-thumb {
  width: 3px;
  background-color: #969696;
  border-radius: 10px;
}

.details::-webkit-scrollbar-track {
  background-color: rgb(236, 236, 236);
}
.details div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  margin-top: 2vh;
  margin-bottom: 2vh;
}

.details div h5 {
  font-family: "Gilroy Semi-Bold";
  width: 70%;
  font-size: var(--heading2); /* 16px; */
}
.details div p {
  font-family: "Gilroy Light";
  width: 70%;
  color: #505050;
  font-size: var(--body); /* 12px; */
}
.details div p u {
  cursor: pointer;
}
.details div h3 img {
  height: 16px;
  margin-right: 6px;
}

.details div h6 {
  font-family: "Gilroy Regular";
  width: 30%;
  padding-left: 1vw;
  color: #505050;
  font-size: var(--subheading2); /* 12px; */
}
.creator {
  font-family: "Gilroy Regular";
  width: 70%;
  color: #505050;
  font-size: var(--body); /* 12px; */
}
.line {
  color: #e6e6e6;
  height: 0.08vh;
}
.noDetails {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.noDetails p {
  font-size: var(--body); /* 12px; */
}
