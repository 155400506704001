.tooltip-containerr {
	position: relative;
	display: inline-block;
}

.tooltip-boxx {
	visibility: hidden;
	background-color: #0e1b6b; /* Tooltip background */
	color: #fff; /* Tooltip text color */
	text-align: center;
	border-radius: 6px;
	padding: 8px 10px;
	position: absolute;
	top: 125%; /* Position the tooltip below the element */
	left: 50%;
	transform: translateX(-50%);
	z-index: 1;
	white-space: nowrap;
}

.tooltip-containerr:hover .tooltip-boxx {
	visibility: visible;
}

.tooltip-arroww {
	position: absolute;
	bottom: 100%; /* Position the arrow on the top edge */
	left: 50%;
	transform: translateX(-50%);
	border-width: 5px;
	border-style: solid;
	border-color: transparent transparent #0e1b6b transparent;
}

.tooltip-textt {
	font-size: 10px;
}
