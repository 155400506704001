.rootContainer {
  font-family: "Gilroy Regular";
  margin-top: 2vh;
  /* height: 60vh; */
  width: 100%;
}
.topBar {
  height: 38px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
}
.category {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: -20px;
}
.category div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 1.5vw;
}
.categoriesTextTotal {
  font-size: var(--heading2); /* 1.6rem; */
  cursor: pointer;
}
.categoriesTextTotalBold {
  font-family: "Gilroy Semi-Bold";
}
.categoriesTextBold {
  font-family: "Gilroy Semi-Bold";
  font-size: var(--subheading1); /* 1.5rem; */
  cursor: pointer;
}
.categoriesTextBold2 {
  font-family: "Gilroy Semi-Bold";
  font-size: var(--subheading1); /* 1.5rem; */
  margin-right: 1.5vw;
  cursor: pointer;
}
.categoriesText {
  font-size: var(--body); /* 1.2rem; */
  margin-right: 1.5vw;
  cursor: pointer;
}
.categoriesTextFirst {
  font-size: var(--body); /* 1.2rem; */
  cursor: pointer;
}
.searchInput {
  background-color: white;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #dcdcdc;
  padding-right: 0.5vh;
  padding-right: 0.5vh;
  height: 38px;
  width: 18.5vw;
  max-width: 345px;
}
.searchInput input {
  width: 17vw;
  font-family: "Gilroy Light";
  font-size: var(--body); /* 1.2rem; */
  margin-left: 0.5vw;
  border: none;
  outline: none;
  cursor: text;
}
.searchInput img {
  /* width: 19.3px; */
  height: var(--heading); /* 18px; */
  cursor: pointer;
  margin-right: 0.5vw;
  margin-left: 1vw;
}
.MediaTiles {
  display: grid;
  width: 100%;
  column-gap: 0.5vw;
  /* row-gap: 3vh; */
  row-gap: 3%;
  /* row-gap: 0.5vw; */
  grid-template-columns: 24.5% 24.5% 24.5% 24.5%;
  grid-template-rows: 8.5vh 8.5vh 8.5vh 8.5vh;
  margin-top: 2vh;
  max-height: 57vh;
  height: 55vh;
}

.MediaTiles-item {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.8);
  padding: 20px;
  font-size: 3rem;
  text-align: center;
}

.MediaNavigation {
  margin-top: 3vh;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
}
.MediaNavigation img {
  height: var(--body); /* 12px; */
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
  &:hover {
    cursor: pointer;
    height: 16px;
  }
}
.navigationButton {
  border: none;
  /* outline: none; */
  margin-left: 5px;
  margin-right: 5px;
  height: 30px;
  width: 35px;
  background-color: white;
  padding-top: 5px;
  color: black;
  font-size: var(--body); /* 1.2rem; */
  &:hover {
    font-size: 1.6rem;
  }
}
.navigationButtonSelected {
  font-family: "Gilroy Semi-Bold";
  font-size: var(--subheading1); /* 1.4rem; */
  background-color: #4256d0;
  color: #f1f1f1;
}
.media-loader {
  margin-top: 2vh;
  max-height: 57vh;
  height: 55vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
}
.categoryOuterCOntainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
