@import url(https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0-beta.2/css/bootstrap.min.css);
/** WARNING - USE AT OWN RISK */
/** IT IS AGAINST APPLE'S POLICY TO USE SF PRO FOR ANYTHING OTHER THAN iOS/tvOS/macOS/watchOS DESIGN & DEVELOPMENT */
/** https://sf.abarba.me/font.css */
/** https://sf.abarba.me/LICENSE.pdf */

/** 1. Copy/import this file into your main css/scss file */
/** 2. Change css font-family: to "SF Text", "SF Display" or "SF Mono" */
/** 3. Apply font-weight or italic to html elements */

/** THANK YOU */
/** I host these fonts on Cloudfront with SSL in all AWS regions for the best performance and reliability */
/** If you'd like to help offset costs, I'd very much appreciate a donation via Lisk https://lisk.io */
/** Address: 14987768355736502769L */
/** Delegate: andrew */

/*---------------------------------------------------------------------------*
 * SF UI Display
 *---------------------------------------------------------------------------*/

/** SF PRo SEmi Bold */
@font-face {
	font-family: 'SF Pro Display Semibold';
	/* font-weight: 900; */
	src: url(/static/media/SF-Pro-Display-Semibold.e570ec81.otf);
}

/** Regular */
@font-face {
	font-family: 'SF Pro Display Regular';
	/* font-weight: 700; */
	src: url(/static/media/SF-Pro-Text-Regular.f38dce0a.otf);
}

/** Medium */
@font-face {
	font-family: 'SF Pro Display Medium';
	/* font-weight: 800; */
	src: url(/static/media/SF-Pro-Text-Regular.f38dce0a.otf);
}
@font-face {
	font-family: 'SF Pro Display Light';
	/* font-weight: 600; */
	src: url(/static/media/SF-Pro-Display-Light.d8b75209.otf);
}
@font-face {
	font-family: 'SF Pro Text Medium';
	/* font-weight: 800; */
	src: url(/static/media/SF-Pro-Text-Medium.b21791d4.otf);
}
@font-face {
	font-family: 'SF Pro Text Bold';
	/* font-weight: 800; */
	src: url(/static/media/SF-Pro-Text-Bold.3abde616.otf);
}
@font-face {
	font-family: 'SF Pro Text Regular';
	/* font-weight: 800; */
	src: url(/static/media/SF-Pro-Text-Regular.f38dce0a.otf);
}


/**Inter Regular Font */
@font-face {
	font-family: 'Inter-Regular';
	src: url(/static/media/Inter-Regular.079af0e2.ttf);
}

@font-face {
	font-family: 'Inter-Black';
	src: url(/static/media/Inter-Black.980c7e87.ttf);
}


@font-face {
  font-family: "Gilroy Light";
  /* font-weight: 900; */
  src: url(/static/media/Gilroy-Light.25e5ce97.ttf) format("truetype");
}
@font-face {
  font-family: "Gilroy Regular";
  /* font-weight: 900; */
  src: url(/static/media/Gilroy-Regular.22d25e11.ttf) format("truetype");
  font-weight: 400;
  /* font-style: normal; */
}
@font-face {
  font-family: "Gilroy Semi-Bold";
  /* font-weight: 900; */
  src: url(/static/media/Gilroy-Medium.d8ee4539.ttf) format("truetype");
}
@font-face {
  font-family: "Gilroy Bold";
  /* font-weight: 900; */
  src: url(/static/media/Gilroy-Bold.889a4b5c.ttf) format("truetype");
}
@font-face {
  font-family: "Gilroy Bolder";
  /* font-weight: 900; */
  src: url(/static/media/Gilroy-Heavy.2b57bf6e.ttf) format("truetype");
}

:root {
  --mainheading: 2.6rem;
  --mainheading2: 2.4rem;
  --heading: 2rem;
  --heading2: 1.8rem;
  --subheading: 1.7rem;
  --subheading1: 1.6rem;
  --subheading2: 1.5rem;
  --body: 1.4rem;
  --body2: 1.3rem;
  --body3: 1.2rem;
  --body4: 1.1rem;
  --body5: 1rem;
}
body {
  /* font-family: 'SF Text' !important; */
  background-color: #242933 !important;
  color: #454f63 !important;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
  /* height: 200%; */
}

p {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.leads-table {
  overflow-y: visible;
}

.assigne__control {
  /* margin-top: -15px; */
  /* height: 28px !important; */
  min-height: 28px !important;
  border-radius: 6px !important;
}

.assigne__value-container {
  position: unset !important;
  justify-content: left;
  overflow: visible;
}

.assigne__indicators {
  height: 28px !important;
}

.ReactTable .rt-thead .rt-tr {
  text-align: left !important;
  /* justify-content: center; */
  align-items: center;
}

.rt-thead .-filters {
  justify-content: center;
  align-items: center;
  height: 28;
}

.rt-thead .-header {
  justify-content: center;
  align-items: center;
  height: 28;
}

.wrapword {
  word-break: break-all;
  height: 150px;
  overflow-y: auto;
}

.wrapwords {
  word-break: break-all;
}

.react-confirm-alert-body > h1 {
  margin-top: 0;
  font-size: 16px !important;
}

.editableTwoLine .editable-container > a {
  /* overflow: hidden; */
  padding-left: 5px;
  color: black !important;
  /* text-overflow: ellipsis;
	white-space: nowrap; */
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
}

.editableOneLine .editable-container > a {
  /* overflow: hidden; */
  padding-left: 5px;
  color: black !important;
  /* text-overflow: ellipsis;
	white-space: nowrap; */
  display: -webkit-box !important;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
  white-space: normal;
  word-break: break-all;
}

.react-datepicker-popper {
  z-index: 20 !important;
}

.ReactTable .rt-thead.-filters input {
  height: 22px;
  background-color: #9d9d9d !important;
}

#root {
  overflow: hidden;
}

.rt-thead {
  /* background-color: #586173; */
  background-color: #707070;
}

.ReactTable .rt-table {
  flex: auto 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  border-collapse: collapse;
}

/* .RexControler > div{
  background-color: red;
}
.StanControler > div{
  background-color: yellow;
}
.AwaisControler > div{
  background-color: blue;
} */
.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: none !important;
}

.ReactTable .rt-thead {
  flex: 1 0 auto;
  display: flex;
  flex-direction: row !important;
  -webkit-user-select: none;
  user-select: none;
}

.ReactTable .rt-tbody {
  overflow-y: hidden;
  font-size: 13px;
  align-items: flex-start;
}

.ReactTable .rt-td {
  flex: 1 0;
  white-space: unset !important;
  text-overflow: ellipsis;
  padding: 7px 5px;
  overflow: hidden;
  transition: 0.3s ease;
  transition-property: width, min-width, padding, opacity;
}

.pagination-bottom {
  width: 100%;
}

.react-datepicker__input-container > input[type="text"] {
  height: 34px;
  width: 74px;
  border-radius: 6px;
  border: none;
  text-align: center;
  font-family: "SF Pro Text Regular";
}

button.Table__pageButton {
  text-transform: uppercase;
  border: none;
  background: transparent;
  padding: 5px;
  color: #0a84fe;
  font-size: 12px;
  font-weight: bold;
}

.Table__visiblePagesWrapper button.Table__pageButton {
  padding-left: 8%;
}

button.Table__pageButton.Table__pageButton--active {
  color: #454f63;
}

button[disabled].Table__pageButton {
  color: #d7d7de;
}

.login_content {
  max-width: 50%;
  margin: auto;
  padding: 10px;
}

.ReactTable .rt-thead.-filters input,
.ReactTable .rt-thead.-filters select {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 5px 7px;
  border-radius: 4px !important;
  font-weight: normal;
  outline: none;
}
.leadScreen .rt-td:first-child {
  font-size: 15px;
  color: #454f63;
  text-align: left;
  font-family: "SF Pro Text Regular";
  letter-spacing: 0;
  align-items: baseline;
  padding: 8px !important;
  border-right: 1px solid #727d943d !important;
  border-bottom: 1px solid #727d943d !important;
  height: 60px;
  display: block;
}
.userScreen .rt-td:first-child {
  font-size: 15px;
  color: #454f63;
  text-align: left;
  font-family: "SF Pro Text Regular";
  letter-spacing: 0;
  align-items: baseline;
  padding: 8px !important;
  border-right: 1px solid #727d943d !important;
  border-bottom: none !important;
  height: 60px;
  display: block;
}
.rt-td {
  font-size: 15px;
  color: #454f63;
  text-align: left;
  font-family: "SF Pro Text Regular";
  letter-spacing: 0;
  align-items: center;
  padding: 8px !important;
  border-right: 1px solid #727d943d !important;
  border-bottom: 1px solid #727d943d !important;
  height: 60px;
  display: flex;
  width: 800px;
}

.assigne__control.css-1sp4s6o-control {
  border: none;
  margin-top: -13px;
}

.assigne__single-value.css-1dkzy1c-singleValue {
  /* color: white !important; */
  font-size: 14px !important;
}

.tabElement-btn-active {
  background-image: "url()";
}

input::-webkit-color-swatch-wrapper {
  padding: 0;
}

input::-webkit-color-swatch {
  border: none;
  border-radius: 6px;
}

/* .css-tj5bde-Svg > path{
	color: #545454 !important;
} */
.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  border-right: 1px solid #727d94 !important;
  padding: 5px 8px !important;
}

.no-left-right-margin-important {
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.rt-td:first-child {
  border-right: 0 !important;
}

/* .rt-th:nth-child(2) {
  /* background: #454f63; */
/* } */

.css-2b097c-container {
  width: -webkit-fill-available;
  margin-top: 15px;
  margin-top: 0px;
}

.draggable-header {
  text-transform: uppercase;
  color: #e6e6eb;
  font-family: "SF Pro Text Medium" !important;
  font-size: 10;
  text-align: left;
  letter-spacing: 0.5px !important;
}

.rt-thead.-filters .rt-tr select.select {
  height: 27px;
  text-align: center;
  text-align-last: left;
  font-size: 11px;
  width: 100%;
  padding: 5px;
  line-height: 1;
  border: 0;
  color: black;
  background: url(/static/media/dropdown.d8395579.svg) no-repeat
    right #fff;
  -webkit-appearance: none;
  background-size: 11px;
  background-position-x: Calc(100% - 10px);
}

.rt-thead.-filters {
  height: 28px;
}

.rt-thead.-header {
  height: 28px;
}

.rt-thead .rt-th {
  margin-bottom: 9px !important;
}

.rt-tr-group {
  height: 58px;
}

.assigne__control .css--control {
  height: 33px !important;
  border-radius: 8px;
}

button {
  outline: none !important;
}

.react-datepicker__input-container > input[type="text"] {
  height: 33px;
  width: 74px;
  border-radius: 6px;
  border: none;
  text-align: center;
  font-family: "SF Pro Text Regular";
}

.rt-resizable-header-content {
  text-transform: uppercase;
  color: #e6e6eb;
  font-family: SF Pro Text Medium;
  font-size: 10px;
  text-align: left;
}

.rt-td input[type="color"] {
  height: 30px !important;
  width: 100% !important;
  outline: none;
}

.rt-td input[type="text"] {
  font-family: "SF Pro Text Regular" !important;
  font-size: 13px;
  width: 100% !important;
  border-radius: 4px;
  border: none;
  /* height: 28px; */
  align-items: center;
  padding-left: 10px;
}

.rt-th select option {
  font-family: "SF Pro Display Regular";
  font-size: 11px;
}

.assigne__indicator.assigne__dropdown-indicator.css-tlfecz-indicatorContainer {
  margin-top: 12px;
}

.rt-th input {
  font-family: "SF Pro Display Regular";
  font-size: 11px;
}

input#formBasicText {
  width: 92% !important;
  height: auto;
  margin-left: 10px;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  height: 36px !important;
  padding: 7px 8px !important;
}

.rt-thead.-header .rt-th {
  margin-top: 15px;
}
.margin-zero {
  margin: 0px !important;
}
input[type="text"]::placeholder {
  font-size: 12px;
  color: #d7d7de;
  font-family: "SF Pro Text Medium";
}

.popup-content {
  width: 300px !important;
  word-break: break-all;
  max-height: 400px !important;
  overflow: hidden;
  overflow-y: auto;
}

.add-new-lead-popup-content {
  width: 35% !important;
  word-break: break-all;
  background-color: #586173 !important;
}
.lead-source-popup-content {
  min-width: 50% !important;
  max-width: 80vw;
  max-height: 80vh;
  word-break: break-all;
  /* background-color: #586173 !important; */
  background-color: #fff !important;
  padding: 0 !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.settings-footer-buttons-container {
  margin-top: 1vh;
  width: 100%;
}
.settings-footer-buttons-container button {
  min-height: 3.2rem;
  min-width: -moz-fit-content;
  min-width: fit-content;
  width: 4vw;
  /* max-width: 7vw; */
  margin-right: 1rem;
  font-size: 1.3rem;
}
.rt-td td {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: black;
}

.form-group {
  margin-bottom: 1rem;
  width: auto !important;
  /* height: 0; */
}

textarea#formBasicTextarea {
  height: auto !important;
  width: 207px !important;
}
/* .leadScreen .rt-tr.-padRow {
  padding-left: 52px;
} */
/* .userScreen .rt-tr.-padRow {
    padding-left: 43px;
} */
.userScreen.ReactTable .rt-tbody .rt-td {
  border-right: 1px solid #727d943d !important;
}
.userScreen.ReactTable .rt-tbody {
  flex: 99999 1 auto;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
/* Assignee Drop Down indicator seprator width and path color according to Assigned and UnAssigned Values */
.assigned__indicator svg path {
  color: black;
}

.assigne__indicator svg path {
  color: white;
}

span.assigned__indicator-separator {
  width: 0px !important;
}

span.assigne__indicator-separator {
  width: 0px !important;
}

.ReactTable .rt-thead .rt-resizable-header-content {
  overflow: hidden;
  text-overflow: ellipsis;
  letter-spacing: 0.5px !important;
}
.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
  max-height: 60px;
}
.header.row {
  min-height: 60px;
  max-height: 70px;
}
.snoozeoption:hover {
  background-color: rgb(216, 214, 214);
}
/* .react-datepicker-wrapper {
  width:100%;
} */
.font-siize-12 {
  font-size: 12px;
  margin-bottom: 0px !important;
}
.text-muted-add-lead {
  color: #bfc4ca;
  font-size: 10px;
}
.mhr-custom-select {
  position: relative;
  width: 200px; /* Set the width of the custom select */
}

.mhr-selected-option {
  border: 1px solid #ccc;
  padding: 10px;
  cursor: pointer;
}

.mhr-options {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%; /* Set the width of the dropdown menu */
  border: 1px solid #ccc;
  background-color: white;
  display: none;
}

.mhr-options li {
  padding: 10px;
  cursor: pointer;
  color: black;
}

.mhr-options li:hover {
  background-color: #f0f0f0;
}

.mhr-custom-select:hover .options {
  display: block;
}
.mhr-no-data {
  width: 100%;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}
.mhr-no-data h6 {
  color: #0a84fe;
  cursor: pointer;
  font-size: 12px;
}
.mhr-no-data h6:hover {
  color: #064b90;
}
.mhr-seqeunce-container {
  border: none;
  min-height: 25px;
  min-width: max-content;
  border-radius: 5px;
  /* 20px; */
  background-color: #4256d0;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  min-height: 27px;
  height: 27px;
  max-height: 27px;
  /* padding: 3px 10px; */
  cursor: pointer;
  border: 2px solid #2c3a91;
  /* z-index: 2; */
}
/* .absolute-right {
  position: absolute;
  right: 0;
} */
.sequence-container-sms {
  min-width: 90%;
  max-width: 90%;
  width: 90%;
  /* max-width: 100%; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 7px;
  /* position: relative; */
}
.sequence-container {
  min-width: 90%;
  max-width: 90%;
  width: 90%;
  min-height: 27px;
  height: 27px;
  max-height: 27px;
  /* max-width: 100%; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* padding-right: 10px; */
  /* position: relative; */
}
.mhr-seqeunce-container img {
  height: 10px;
  margin-right: 0.3vw;
  margin-bottom: 1px;
}
.mhr-seqeunce-container p {
  font-size: 10px;
  font-family: "Gilroy Semi-Bold";
}
.mhr-seqeunce-container-yellow {
  background-color: #f2bd00;
  min-height: 24px;
  border: 2px solid #c89d00;
}
.mhr-seqeunce-container-yellow img {
  height: 12px;
}
.mhr-seqeunce-container-green {
  background-color: #53dba7;
  min-height: 100%;
  border: 2px solid #46ba8e;
  margin-right: 0;
  margin-left: 0.3vw;
  overflow: hidden;
}
.mhr-seqeunce-container-green p {
  padding-top: 1px;
}
.mhr-seqeunce-container-green img {
  height: 14px;
  width: 14px;
  margin-left: 0.3vw;
  margin-bottom: 0px;
  margin-right: -0.2vw;
}
.email-hotlist-mhr {
  display: flex;
  /* flex: 1; */
  padding-left: 20;
  /* width: 100%; */
}

.mhr-seqeunce-container-played {
  /* position: absolute;
  right: 0; */
  border: none;
  min-height: 24px;
  /* min-width: max-content; */
  min-width: 85px;
  width: 85px;
  /* max-width: 90px; */
  border-radius: 5px;
  /* 20px; */
  background-color: #4256d0;
  border: 2px solid #2c3a91;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 27px;
  height: 27px;
  max-height: 27px;
  /* padding-top: 3px;
  padding-bottom: 3px; */
  padding-left: 5px;
  padding-right: 5px;
}
.mhr-seqeunce-container-played-img {
  height: 15px;
  margin-right: 5px;
  cursor: pointer;
}
.mhr-seqeunce-container-played div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.mhr-seqeunce-container-played div div {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 1px solid white;
  border-radius: 100%;
  width: 13px;
  max-width: 13px;
  min-width: 13px;
  min-height: 12px;
  height: 12px;
  max-height: 12px;
  margin-right: 4px;
}
.mhr-seqeunce-container-played p {
  text-align: center;
  font-size: 10px;
  color: #4256d0;
}
.mhr-sequence-checked-image {
  height: 15px;
  margin-top: 1.22px;
  margin-right: 2px;
}
.spinner-container-mhr {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.templateTiles::-webkit-scrollbar {
  width: 5px;
}
.templateTiles::-webkit-scrollbar-thumb {
  width: 3px;
  background-color: #a2a2a2;
  border-radius: 10px;
}

.templateTiles::-webkit-scrollbar-track {
  background-color: rgb(236, 236, 236);
}
.loc-category-main {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}
.loc-category-main img {
  width: 28px;
  height: 28px;
  cursor: pointer;
}
.loc-category-main div {
  min-width: "fit-content";
  width: 11.75rem;
  margin-left: 10px;
  border: 2px solid #454545;
  border-radius: 4px;
  cursor: pointer;
  min-height: 27px;
  height: 27px;
  max-height: 27px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.loc-category-main div p {
  font-size: 1.1rem;
  max-lines: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  color: white;
  text-align: center;
}
.ulDiv ul {
  list-style: none;
  padding: 0;
}
li {
  line-height: normal;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.tabElement {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 168px;
  height: 100%;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  /* font-weight: 400; */
  font-family: "SF Pro Display Semibold";
}
.ExportElement {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33%;
  height: 100%;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  /* font-weight: 400; */
  font-family: "SF Pro Display Semibold";
}
.ExportElement > button {
  border: none;
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: contain;
  cursor: pointer;
  width: 45px;
  height: 19px;
  /* align-self: center; */
  margin-right: 5px;
}
.ExportElement > span {
  font-size: 14px;
  /* font-weight: 300; */
  /* margin-left: 5px; */
}
.ExportElement:hover {
  color: #0a84fe;
}

.active-tab {
  text-align: center;
  border-top: 4px solid #4256d0;
  color: black;
  /* font-weight: 400; */
  font-family: "SF Pro Display Semibold";
}
.completed-tab {
  border-top: 4px solid #00ff22;
}
.aging-tab {
  border-top: 4px solid #ff0000;
}
.inprogress-tab {
  border-top: 4px solid #ffa600;
}
.fresh-tab {
  border-top: 4px solid #0004ff;
}
.-header {
  color: #ebe7e7;
}
.tabElement > button {
  border: none;
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: contain;
  cursor: pointer;
  width: 45px;
  height: 19px;
  /* align-self: center; */
  margin-right: 5px;
}
.tabElement > span {
  font-size: 14px;
  color: black;
  /* font-weight: 300; */
  /* margin-left: 5px; */
}
.tabElement:hover {
  color: #0a84fe;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* generic classes */
.flex-row-center {
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
}
.flex-row-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.flex-row-space {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: center;
}
.modal-footer-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 20px;
}
.draggable-header {
  text-transform: uppercase;
  color: #e6e6eb;
}
.attach-file-save-button {
  outline: none;
  background: #333;
  border: none;
  display: inline-block;
  padding: 5px 24px;
  color: #eee;
  margin-right: 12px;
  border-radius: 5px;
  font-size: 13px;
  width: 20%;
  cursor: pointer;
}

.note-edit-modal-save-button {
  outline: none;
  background: #333;
  border: none;
  display: inline-block;
  padding: 5px 24px;
  color: #eee;
  margin-right: 12px;
  border-radius: 5px;
  font-size: 13px;
  cursor: pointer;
}
.temp-modal-save-button {
  outline: none;
  background: #333;
  border: none;
  display: inline-block;
  padding: 5px 24px;
  color: #eee;
  /* margin-right: 12px; */
  border-radius: 5px;
  font-size: 13px;
  cursor: pointer;
}
.note-modal-save-button {
  outline: none;
  background: #333;
  border: none;
  display: inline-block;
  padding: 5px 24px;
  color: #eee;
  border-radius: 5px;
  font-size: 13px;
  cursor: pointer;
}
.temp-card-save-button {
  outline: none;
  background: #333;
  border: none;
  display: flex;
  padding: 5px 24px;
  color: #eee;
  border-radius: 5px;
  font-size: 13px;
  cursor: pointer;
}

.temp-card-button-cont {
  grid-gap: 10px;
  gap: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 82%;
}
.edit-modal-save-button {
  outline: none;
  background: #333;
  border: none;
  display: inline-block;
  padding: 6px 18px;
  color: #eee;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}
.edit-modal-cancel-button {
  outline: none;
  background: #333;
  border: none;
  display: inline-block;
  padding: 6px 18px;
  color: #eee;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}
.delete-button-container {
  display: "flex";
  align-self: "flex-end";
  background-color: "green";
  padding: 6px 18px;
}
.modal-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
/* .modal-backdrop {
opacity: 0.5 !important;
} */

/* .modal-backdrop {
background: #ffffffe6 !important;
opacity: 0.5 !important;
}

.fade.modal-backdrop.newBackdrop.show {
opacity: 0.8;
} */

.search-bar:focus {
  outline: 2px solid #0a84fe;
  background: #ffffff;
  border: 2px solid #0a84fe;
  box-shadow: -2px 3px 8px 1px rgba(182, 169, 152, 0.3);
  border-radius: 5px;
}

.tooltip-text {
  visibility: hidden;
  width: 100px;
  background-color: #0e1b6b;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
}

.tooltip-text::after {
  content: " ";
  position: absolute;
  bottom: 100%; /* At the top of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.tooltip-text.users-tooltip {
  bottom: -35%;
  left: 33%;
  transform: translate(-50%, 0);
}

.tooltip-text.add-lead-tooltip {
  bottom: -35%;
  left: 20%;
  transform: translate(-50%, 0);
}

.tooltip-text.leads-source-tooltip {
  bottom: -35%;
  left: 8%;
  transform: translate(-50%, 0);
}

.tooltip-text.form-tooltip {
  bottom: -35%;
  left: 45%;
  transform: translate(-50%, 0);
}

.users-icon:hover .tooltip-text.users-tooltip {
  visibility: visible;
}

.leads-icon:hover .tooltip-text.add-lead-tooltip {
  visibility: visible;
}

.leads-source-icon:hover .tooltip-text.leads-source-tooltip {
  visibility: visible;
}

.form-icon:hover .tooltip-text.form-tooltip {
  visibility: visible;
}

.notes-container {
  padding: 10px 40px;
  margin-top: 10;
  overflow-y: scroll;
  height: 50vh;
  /* background-color: #fafaff; */
}
.time-line-conatiner {
  border: 0px 0px 0px 1px;
  border-color: #4256d0;
  border-style: solid;
  position: relative;
  margin-left: 8;
  background-color: #fafaff;
}
.note-info-title {
  padding: 10px;
  font-size: 12px;
}

.followup-modal-save-button {
  outline: none;
  background: #333;

  /* background: #0a84fe; */
  border: none;
  display: inline-block;
  padding: 5px 24px;
  color: #eee;
  border-radius: 5px;
  font-size: 13px;
  cursor: pointer;
  width: 10wv;
  /* position: absolute; */
  right: 40px;
}
.add-temp-button-container {
  display: flex;
  justify-content: flex-end;
  flex: 1 1;
}
.note-divider {
  width: 100%;
  height: 1px;
  background-color: lightgray;
}
.note-info-text-value {
  font-weight: 600;
  font-size: 15px;
  text-align: center;
  padding-top: 3px;
}
.note-input-container {
  margin-top: 0px;
  padding: 0px 35px;
  overflow-y: hidden;
}
.note-info-container {
  display: flex;
  flex-direction: row;
  padding: 5px 35px;
  /* width: 40vw; */
}
.note-info-sub-container {
  display: flex;
  flex-direction: row;
  /* flex: 1; */
  justify-content: flex-start;
  margin-right: 30px;
  align-items: center;
}

.note-input {
  color: black;
  font-size: 14;
  width: 100%;
  border-style: solid;
  border-width: 1px;
  padding: 8px;
  margin: 0px 0px;
  border-color: grey;
  border-radius: 5px;
  height: 50px;
}
.note-lead-name-container {
  font-size: 13px;
  display: flex;
  flex-direction: row;
  padding: 5px 35px;
}
.modal-heading-cont {
  margin: 0px;
  padding: 10px 35px 0px 35px;
  width: 100%;
}
.modal-heading {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  padding: 0px;
  margin: 0px;
  text-align: left;
  font-weight: 600;
  font-size: 24px;
}

.child-modal-container {
  margin-top: 10;
  width: 30vw;
  height: 20vh;
}
.notes-loader {
  position: relative;
  height: 50vh;
  justify-content: center;
  align-self: center;
}
.custom-ui {
  z-index: 1000;
}
.custom-select {
  position: relative;
  display: inline-block;
}

.custom-select select {
  display: none;
}

.custom-select::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #555;
}

.custom-select select,
.custom-select::after {
  cursor: pointer;
}

.custom-select select:focus {
  outline: none;
}
.followup-modal-footer {
  justify-content: space-between;
  display: flex;
  margin: 40px 0px 0px 0px;
  width: 100%;
}
.followup-modal-footer {
  justify-content: space-between;
  display: flex;
  /* margin: 40px 0px 0px 0px; */
  width: 100%;
}
.followup-modal-body-review-normal {
  border: none;
  color: black;
  border-color: transparent;
  border-radius: 3px;
}
.followup-modal-body-review-referral {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 2px solid gray;
  border-radius: 10px;
  font-size: 18px;
  padding-left: 2.5vw;
  padding-right: 2.5vw;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
  margin-left: 0.7vw;
  margin-right: 0.7vw;
  cursor: pointer;
  position: relative;
}
.followup-modal-body-review-referral-selected {
  border-color: #53dba7;
}
.followup-modal-body-review-referral-check {
  position: absolute;
  max-height: 20px;
  max-width: 20px;
  top: -7px;
  right: -14px;
}
.followup-modal-body-review-referral img {
  height: 36px;
  /* width: 32px; */
  margin-right: 5px;
}
.followup-modal-body {
  margin-top: 15px;
  /* row-gap: 20px; */
  display: flex;
  flex-direction: column;
}
.followup-modal-loader-body {
  justify-content: center;
  align-items: center;
  display: flex;
  /* margin: 40px 0px 0px 0px; */
  width: 100%;
  height: 20vh;
}
.followup-form-control {
  width: 450px;
  height: 40px;
  margin: 20px 0px;
}
.followup-note-lead-name-container {
  /* width: 50vw; */
  font-size: 14px;
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  /* padding: 20px 20px; */
}
.tester-label {
  /* width: 50vw; */
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-left: 4px;
  align-items: center;

  /* margin-top: 15px; */
  /* padding: 20px 20px; */
}
.select-text-title {
  margin-top: 10px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: 600;
  font-size: 14px;
}
.followup-modal-heading {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  padding: 0px;
  margin: 0px;
  text-align: left;
  font-weight: 500;
  font-size: 18px;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  margin-right: 10px;
  border: 2px solid #ccc;
  border-radius: 6px;
  color: gray;
  padding-left: 0.7vw;
  padding-right: 0.7vw;
  padding-top: 0.8vh;
  padding-bottom: 0.8vh;
  position: relative;
}
.followup-modal-heading-img1 {
  position: absolute;
  width: 15px;
  height: 15px;
  top: -8px;
  right: 10%;
  left: 90%;
}
.followup-modal-heading-img2 {
  position: absolute;
  width: 18px;
  height: 18px;
  top: -8.5px;
  right: 18%;
  left: 82%;
}
.selected-followup-modal-heading {
  border-color: #4256d0;
  color: #4256d0;
}
.normal-followup-modal-heading {
  border: none;
  color: black;
  margin: 0;
  padding: 0;
  font-size: 22px;
  font-weight: 500;
}
.followup-sub-heading {
  font-size: 14px;
  font-weight: 600;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  padding-bottom: 3px;
}
.followup-select-option {
  width: 400px;
  padding: 5px 0px 5px 10px;
}
.add-temp-input {
  width: 100%;
  border-color: lightgrey;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  height: 6vh;
  font-size: 1.4em;
  margin-bottom: 10px;
  padding: 5px 10px;
}
.add-temp-modal-textarea {
  height: 80px;
  padding: 10px;
  width: 100%;
  border-color: lightgrey;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  font-size: 1.4em;
}

.add-temp-modal-body {
  padding: 10px 20px;
  width: 100%;
}
.ReactTable .rt-thead .rt-tr {
  width: 100%;
  justify-content: flex-start;
}
.ReactTable .rt-tbody {
  overflow: hidden; /* scroll; */
  /* padding-left: 30px; */
  /* width: 100%; */

  align-items: flex-start;
}
.option-add-temp-cont {
  width: "100%";
  display: flex;
  flex-direction: row;
}
.add-temp-note-button-text {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 5px;
}
.add-temp-button {
  font-size: 17px;
  height: 20px;
  width: 20px;
  border-radius: 50px;
  border-color: grey;
  color: grey;
  text-align: center;
  border-style: solid;
  border-width: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.temp-del-text {
  color: crimson;
}
textarea::placeholder {
  color: #282c34;
}
.template-textarea {
  height: 80px;
  padding: 10px;
  border-radius: 5px;
  font-size: 13px;
  color: #282c34;
}
.refRev-checkbox-container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.refreview-modal {
  width: 70vw;
}

.refRev-tester-container {
  width: 50%;
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 0px 0px 10px;
  margin-right: -20px;
}
.test-receiver-textarea {
  margin-top: 5px;
  /* height: 80px; */
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  font-size: 13px;
  border-style: solid;
  border-width: 1px;
  border-color: lightgrey;
  color: #282c34;
}
.MuiButtonBase-root {
  background-color: #00ff22;
}
.text-area-container {
  display: flex;
  flex: 1 1;
  width: "100%";
  flex-direction: column;
  margin-top: 30;
}
.call-rail-select-cont {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.all-rail-select-form {
  background-color: #00ff22;
  width: 100%;
  height: 6vh;
}
.call-rail-source-value {
  align-self: center;
  color: black;
  font-size: 13px;
  line-height: 1.1;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 10vw;
}
.heading-medium {
  padding: 10px 0px;
}
.template-screen-body {
  padding: 60px;
  display: flex;
  flex-direction: column;
}
.temp-card-inner-cont {
  display: flex;
  flex-direction: row;
  /* column-gap: 10px; */
  overflow-x: scroll;
  /* width: 98vw; */
}
.temp-card-container {
  padding: 0px 0px 20px 50px;
  margin: 10px;
  width: 90%;
  /* border-color: #ececf3;
border-width: 1px;
border-style: solid;
border-radius: 5px; */
  /* background-color: #61dafb; */
  /* width: 85vw; */
}
.sub-heading-cont {
  /* padding: 0px 40px 0px 0px; */
  margin: 20px 0px 10px 0px;
  /* position: relative; */
  /* background-color: #e6e6eb; */
}
.template-screen-loader-body {
  height: 70vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.temp-card-cont {
  height: -moz-fit-content;
  height: fit-content;
  width: 22vw;
  border-width: 1px;
  border-color: lightgrey;
  border-style: solid;
  padding: 0px;
}
.filter-select {
  height: 4vh;
  border-radius: 5px;
  margin-left: 3px;
  font-size: 13px;
}

.temp-card-cont-1 {
  align-items: center;
  height: -moz-fit-content;
  height: fit-content;
  width: 30%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  padding: 0px;
}

.temp-card-filter-cont {
  height: 3vh;
  width: 30%;
  display: flex;
  justify-content: flex-end;
  padding: 0px;
}
.temp-card-cont-2 {
  height: -moz-fit-content;
  height: fit-content;
  width: 35%;
  height: fit-content;
  display: flex;
  justify-content: flex-end;
  padding: 0px;
}
.template-attachment {
  padding: 0px 0px 0px 0px;
  white-space: nowrap;
  width: 20vw;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.card-checkbox-container {
  width: 7vw;
  position: relative;
  display: flex;
  flex-direction: row;
}
.heading-cont {
  width: 60%;
}
.heading-cont-refModal {
  width: 80%;
}
.test-checkbox-container {
  width: 40%;
  /* width: 15vw; */
  position: relative;
  display: flex;
  flex-direction: row;
  /* align-items: flex-end; */
  justify-content: flex-end;
}
.template-card-body {
  height: 18vh;
  /* width: 250vw; */
  padding: 5px 5px 0px 5px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.slick-next {
  color: red !important; /* Set the desired color */
  background-color: transparent; /* Set the desired background color */
}
.slick-prev {
  font-size: 60px;
}
.slick-prev:before,
.slick-next:before {
  height: 30px;
  width: 30px;
  color: gray !important;
}
.loader-cont {
  display: flex;
  flex-direction: row;
}
/* .slider {
  width: 96vw;
margin-left: 20px;
padding-left: 10px;
} */
.action-cont {
  border-top: lightgray;
  border-top-width: 1px;
  border-top-style: solid;
  padding: 0px;
  margin: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.action-button-cont {
  flex-direction: row;
  display: "flex";
  justify-content: "space-between";
  background-color: #ffa600;
  width: "100%";
}
.attachment-cont {
  padding: 5px 10px 5px 25px;
}

.card-action-button {
  height: 3vh;
}
.line {
  width: 100%;
  height: 1px;
  background-color: lightgray;
}
/* .MuiSelect-root ;
background-color: #00ff22;
} */
textarea::-webkit-input-placeholder {
  color: grey;
}
.ql-container {
  display: flex;
  flex-direction: column-reverse;
  padding: 0px;
  /* flex: 1; */
  /* background-color: #0004ff; */
  /* height: 5vh; */
  overflow: hidden;
  border: none;
}
.typo-nowrap {
  white-space: nowrap;
  width: 12vw;
  padding-left: 2px;
  overflow: hidden;
  text-overflow: ellipsis;

  /* style={{ fontSize: 13, paddingLeft: 2, overflow: "hidden", textOverflow: "ellipsis" }} */
}

.ql-toolbar {
  order: 2;
  margin: 0px;
  /* width: 900px; */
}

.ql-editor {
  order: 1;
  margin: 0px;
}
.display-linebreak {
  white-space: pre-line;
}
.upload-text {
  position: "absolute";
  background-color: #555;
  z-index: 1;
  left: 0;
  top: 0;
}
.ReactTable .checkbox-input1 {
  z-index: 999 !important;
  /* height: 30rem; */
}
.custom-header .rt-thead .rt-th,
.custom-header .rt-thead .rt-td {
  /* height: 36px !important; */
  padding: 7px 8px !important;
  background-color: #00ff22 !important;
}
.invalidEmailIcon {
  width: 24px;
  height: 24px;
  margin-right: 5px;
  &:hover {
    cursor: pointer;
    background-color: #f1f1f1;
    border-radius: 100%;
  }
}

.nav-tabs .nav-link.active {
	background-color: #007bff !important; /* Change this to your desired color */
	color: white !important; /* Ensure the text is readable */
	border-color: #007bff !important; /* Match the border color */
}

.placeholder-color::-webkit-input-placeholder {
	color: lightgray;
}
.custom-modal-header .close {
	font-size: 2rem; /* Adjust the size as needed */
}
.modalContainer {
	border-radius: 5px;
	background-color: white;
	position: fixed;
	top: 500%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-height: 90vh;
	max-width: 90vw;
	width: 35vw; /* Adjust based on your default preferred width */
	height: 39vh; /* Adjust based on your default preferred height */
	overflow-y: auto;
	overflow-x: hidden;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 20px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.modalContainer1 {
	border-radius: 5px;
	background-color: white;
	/* position: fixed; */
	top: 500%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-height: 90vh;
	max-width: 90vw;
	width: 35vw; /* Adjust based on your default preferred width */
	height: 50vh; /* Adjust based on your default preferred height */
	overflow-y: auto;
	overflow-x: hidden;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 20px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Adjustments for smaller screens */
@media (max-width: 1200px) {
	.modalContainer {
		width: 30vw;
		height: 40vh;
	}
}

@media (max-width: 992px) {
	.modalContainer {
		width: 40vw;
		height: 45vh;
	}
}

@media (max-width: 768px) {
	.modalContainer {
		width: 50vw;
		height: 50vh;
	}
}

@media (max-width: 576px) {
	.modalContainer {
		width: 70vw;
		height: 60vh;
	}
}

@media (max-width: 400px) {
	.modalContainer {
		width: 90vw;
		height: 70vh;
	}
}

.modal-title {
	font-size: 1.5rem; /* Increase the font size of the title */
	color: #0056b3; /* A decent blue color */
}

.modal-body {
	font-size: 1.2rem; /* Slightly larger text inside the modal body */
}

.custom-checkbox .form-check-input {
	accent-color: #007bff; /* Custom blue color for the checkbox */
}

.custom-checkbox .form-check-label {
	padding-left: 0.5rem; /* Add some space after the checkbox */
	color: #333; /* Dark text color for better readability */
}

.custom-checkbox {
	margin-bottom: 0.75rem; /* Add space between each checkbox item */
}

.snoozeoption:hover{
    background-color: 'grey';
}
.generic-dropdown {
  font-family: "Gilroy Regular";
  font-size: 12px;
  width: 100%;
  position: relative;
}
.arrowContainer {
  padding: 2%;
  height: 100%;
  width: 8%;
  border-left: 1px solid #ccc;
}
.noBorder {
  border-left: none !important;
}
.yesBOrder {
  border-left: 1px solid #ccc;
}
.arrowContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.generic-dropdown-text {
  -webkit-user-select: none;
          user-select: none;
  padding: 2%;
  width: 92%;
  height: 100%;
  max-height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.generic-dropdown-text p {
  max-lines: 1;
  text-align: start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.arrowContainer img {
  height: 6.15px;
}
.generic-dropdown-button {
  border: 1px solid #ccc;
  max-height: 40px;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.rounded-dropdown-border {
  border-radius: 4px;
}
.generic-dropdown-menu {
  width: 100%;
  max-height: 145px;
  border: 1px solid #ccc;
  background-color: #fff;
  overflow: hidden;
  overflow-y: auto;
  margin-bottom: 10px;
  z-index: 1;
  position: absolute;
}
.generic-dropdown-item {
  padding: 2%;
  cursor: pointer;
  &:hover {
    background-color: #f2f2f2;
  }
}

.emailModal1111 {
  font-family: "Gilroy Semi-Bold";
  margin: auto;
  border-radius: 10;
  min-height: 100vh;
  height: 100vh;
  max-height: 100vh;
  background: rgba(39, 38, 38, 0.5);
  /* top: 60%; */
  padding-top: 10vh;
}
.headingEmailModal1111 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 3vw;
  margin-right: 3vw;
  margin-top: 5vh;
}
/* .inner-outer-div {
  max-height: 80vh;
  overflow: hidden;
  overflow-y: auto;
} */
.headingEmailModal h2 {
  font-family: "Gilroy Semi-Bold";
  font-size: 2.8rem !important;
}
.headingEmailModal img {
  width: 32px;
  height: 32px;
  cursor: pointer;
}
.emailModalLine {
  margin-top: 2vh;
  height: 1px;
  width: 100%;
  background-color: #9f9f9f;
}

.buttonsContainerModal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 3vw;
  margin-right: 3vw;
  /* margin-top: 1vh; */
  margin-bottom: 3vh;
}
.buttonsContainerModal button {
  width: 49%;
  height: 38px;
  color: white;
  font-size: 1.6rem !important;
  border: none;
}
.cancelButton {
  background-color: #454545;
}
.sendButton {
  background-color: #4256d0;
}
.modalLoader {
  display: flex;
  flex-direction: row;
  width: 49%;
  height: 38px;
  justify-content: center;
  align-items: center;
}

/* =================== */

.SequenceStartModalBody {
  margin-top: 3vh;
  margin-bottom: 6vh;
  margin-left: 3vw;
  margin-right: 3vw;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
}
.SequenceStartModalBody p {
  font-family: "Gilroy Regular";
  font-size: 1.6rem;
}
.SequenceStartModalBody-sh {
  font-family: "Gilroy semi-bold" !important;
  font-size: 1.8rem !important;
}
.SequenceStartModalBody input {
  /* width: 100%;
  height: 40px;
  padding: 20px;
  font-family: "Gilroy Light";
  font-size: 12px;
  border: none;
  border: 1px solid #ccc; */
  width: 0;
  height: 0;
  border: none;
  outline: none;
}
.viewSequenceDetails {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  color: #4256d0;
  border: none;
  border: 1px solid #4256d0;
  padding: 1% 3%;
  visibility: hidden;
  &:hover {
    cursor: pointer;
    background-color: #e7e7e7;
  }
}
.viewSequenceDetails-visible {
  visibility: visible;
}

.viewSequenceDetails img {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
.viewSequenceDetails p {
  font-size: 1.3rem;
}
.seuqneceLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 20vh;
}

.ReminderCustomDateSelector1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #cecece;
  height: 40px;
  font-family: "Gilroy Light";
  /* font-size: 1.1rem; */
  width: 100%;
}
.ReminderCustomDateSelector1 img {
  height: 24px;
  width: 24px;
}
.reminder-date-show1 {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 5%;
}
.reminder-date-show1 p {
  /* font-size: 1.3rem; */
  color: #454545;
}
.reminder-date1 {
  min-width: 40px;
  width: 10%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.Sequence-details-main-container {
  /* overflow: hidden;
  overflow-y: auto; */
  width: 100%;
}
.Sequence-details-main-container p {
  font-size: 1.4rem;
}
.Sequence-details-main-container h3 {
  font-family: "Gilroy Semi-Bold";
  font-size: 2.2rem;
  padding-top: 5px;
}
.Sequence-details-main-container hr {
  background-color: black;
  height: 0.3px;
  margin-bottom: 20px;
}
.tile-outer-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  width: 100%;
  height: 190px;
  min-height: 150px;
  /* max-height: 150px; */
}
.number-container {
  width: 15%;
  height: 150px;
  min-height: 150px;
  /* max-height: 150px; */
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}
.number-container p {
  font-size: 14px;
  border: 1px solid #d9d9d9;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  text-align: center;
  padding-top: 3px;
}
.number-container-line2 {
  height: 100%;
  width: 1px;
  background-color: #d9d9d9;
}

.tile-container {
  width: 100%;
}
.tile-container hr {
  height: 0.3px;
  background-color: #eeeeee;
}
.description-container {
  min-height: 55px;
}
.tile-container h3 {
  font-size: 1.8rem;
}
.tile-container p {
  font-size: 1.5rem;
  text-overflow: ellipsis;
  max-lines: 5;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
}
.tile-container h5 {
  font-size: 1.6rem;
}
.creator-container {
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}
.creator-container div {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}
.creator-outer-border {
  border: 1.5px solid #d9d9d9;
  border-radius: 10px;
  margin-right: 20px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 8px;
  padding-right: 8px;
}
.creator-container img {
  height: 14px;
  margin-right: 5px;
}
.creator-container p {
  max-lines: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
}
.sequence-number-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin-bottom: 5px;
}
.sequence-number-container img {
  height: 17px;
  margin-right: 10px;
}
.sequence-number-container-p {
  font-size: 12px;
  font-family: "Gilroy Semi-Bold";
}
.Sequence-details-main-container button {
  background-color: #454545;
  color: white;
  height: 38px;
  width: 214px !important;
  margin-top: 40px;
}

.Sequence-details-main-container button div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.Sequence-details-main-container button img {
  height: 12px;
  width: 22px;
  margin-right: 10px;
}
.Sequence-details-main-container button p {
  padding-top: 2px;
  font-size: 17px;
}

.mhr-confirmModalContainer {
  font-family: "Gilroy Regular";
}
.mhr-descriptionContainer {
  padding: 25px;
}
.mhr-descriptionContainer h3 {
  font-size: 2rem;
  padding-bottom: 15px;
  font-family: "Gilroy Semi-Bold";
}
.mhr-descriptionContainer p {
  font-family: "Gilroy Light";
  font-size: 1.6rem;
}
.mhr-buttonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
  margin-right: 10px;
}
.mhr-buttonsContainer button {
  outline: none;
  background: #333;
  border: none;
  display: inline-block;
  padding: 5px 24px;
  color: #eee;
  margin-right: 12px;
  border-radius: 5px;
  font-size: 1.3rem;
  cursor: pointer;
}
.mhr-buttonsContainer2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-right: 10px;
}
.mhr-buttonsContainer2 button {
  outline: none;
  /* background: #333; */
  border: none;
  display: inline-block;
  padding: 5px 24px;
  color: #eee;
  margin-right: 12px;
  /* border-radius: 5px; */
  font-size: 1.5rem;

  cursor: pointer;
  width: 19.5rem;
  height: 3.8rem;
}
.mhr-buttonsContainer2-button1 {
  background-color: #454545;
  &:hover {
    background-color: #636363;
  }
}
.mhr-buttonsContainer2-button2 {
  background-color: #f2bd00;
  &:hover {
    background-color: #ffce1c;
  }
}
.mhr-loader-button {
  width: 100px;
  height: 3.8rem;
  margin-right: 12px;
  /* margin-top: 2px; */
  cursor: not-allowed;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

/* ========================== */
.modall {
  transition: width 2s, height 2s, transform 2s;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Gilroy Regular";
  overflow: hidden;
}
.ModalBody {
  min-width: 30%;
  width: min-content;
  max-width: 80%;
  height: 80%;
  background-color: white;
  overflow: hidden;
  overflow-y: auto;
}
.ModalBody2 {
  width: 65%;
}
.ModalBody3 {
  width: 80%;
}

/* ========================== */

.SequencePauseDetailsModalBody {
  margin-top: 3vh;
  padding-bottom: 3vh;
  margin-left: 3vw;
  margin-right: 3vw;
}

.SequencePauseDetailsModalBody hr {
  width: 100%;
  height: 1px;
  background-color: black;
}

.TileAndDateOuterContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.TileAndDateOuterContainer p {
  font-size: 1.4rem;
}
.TileAndDateOuterContainer h3 {
  padding-top: 4px;
  font-size: 2.4rem;
  white-space: nowrap;
}
.TitleContainerSequenceDetails {
  width: 65%;
}
.DateContainerSequenceDetails {
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.TempalateDetailsOuterContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.TemplateOneDetailsContainer {
  width: 100%;
}
.TemplateTwoDetailsContainer {
  width: 50%;
}
.TemplateThreeDetailsContainer {
  width: 33.33%;
}
.TemplateOneDetailsContainer h2 {
  font-size: 20px;
  color: #007d0d;
  font-family: "Gilroy Semi-Bold";
}
.scheduleContainer div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}
.scheduleContainer div img {
  margin-left: 8px;
  height: 16px;
}
.scheduleContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  min-height: 50px;
  height: 50px;
  max-height: 50px;
  color: black;
}
.scheduleContainer h3 {
  font-size: 1.6rem;
  font-family: "Gilroy Semi-Bold";
  color: black;
}
.scheduleContainer p {
  font-size: 1.4rem;
  font-family: "Gilroy Semi-Bold";
  color: #626262;
}
.StatusContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1vh;
}
.StatusContainer img {
  width: 30px;
  height: 30px;
}
.TickEclipse {
  width: 21px;
  height: 20px;
  background-color: #d9d9d9;
  border: 1px solid #d9d9d9;
  border-radius: 100%;
}
.statusLine1 {
  background-color: #d9d9d9;
  width: 100%;
  height: 2px;
}
.green {
  background-color: #00e917;
}
.TemplateDetailsContainerOuter {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
}
.TemplateDetailsContainerOuter h2 {
  font-size: 1.7rem;
  color: black;
  font-family: "Gilroy Semi-Bold";
}
.TemplateDetailsContainerTitles {
  width: 20%;
  font-size: 1.5rem;
  font-family: "Gilroy Semi-Bold";
}
.TemplateDetailsContainerDescriptions {
  width: 80%;
  font-size: 1.5rem;
  line-height: 16px;
}
.TemplateDetailsContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
}
.DetailsContainerMHR {
  overflow: hidden;
  overflow-y: auto;
  padding-left: 1vw;
  padding-right: 1vw;
  height: 30vh;
  margin-top: 5vh;
}
/* .totalTemplates2 { */
/* margin-top: 1vh; */
/* } */
.totalTemplates3 {
  margin-top: 3vh;
}
.actionButtonS {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  /* padding-bottom: 5vh; */
}

.actionButtonS button {
  border: none;
  outline: none;
  font-family: "Gilroy Semi-Bold";
  font-size: 17px;
  color: white;
  height: 38px;
  width: 195px;
  margin-top: 4vh;
}
.actionButtonS button div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  /* padding-bottom: 5vh; */
}

.actionButton1 {
  background-color: #454545;
  margin-left: 10px;
}
.actionButton3 {
  background-color: #f2bd00;
  margin-left: 10px;
}
.loaderonbutton {
  height: 38px;
  width: 195px;
  margin-top: 4vh;
  /* background-color: #f2bd00; */
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.date-dropdown {
  font-family: "Gilroy Regular";
  -webkit-user-select: none;
          user-select: none;
  font-size: var(--body);
  position: relative;
  height: 100%;
}
.dateDropdownMainContainer {
  font-family: "GIlroy Regular";
  font-size: var(--body);
  width: 100%;
  height: 100%;
  min-height: 42px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #c8c8c8;
  padding-left: 3%;
  background-color: white;
}
.dateDropdownMainContainerNotActive {
  background-color: #f2f2f2;
  cursor: not-allowed;
}
.dateDropdownMainContainerActive {
  &:hover {
    background-color: rgb(242, 241, 241);
    cursor: pointer;
  }
}
.whiteBackground {
  background-color: white;
}
.itemsContainer {
  padding-right: 3%;
  width: 90%;
  overflow: hidden;
}
.itemsContainer p {
  font-family: "Gilroy Semi-Bold";
  max-lines: 1;
  text-align: start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.arrowsContainer {
  width: 15%;
  max-width: 50px;
  height: 100%;
  min-height: 40px;
  border-left: 1px solid #c8c8c8;
  display: flex;
  justify-content: center;
  align-items: center;
}
.arrowsContainer img {
  height: 6.15px;
}
.arrow-container-img-rotate {
  transition: 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  transform: rotate(180deg);
}
.arrow-container-img-rotate-back {
  transition: 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  transform: rotate(0deg);
}
.date-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid #ccc;
  border-bottom: 1.5px solid #ccc;
  /* background-color: #fff; */
  background-color: #fdfdfd;
  overflow: hidden;
  z-index: 1;
}

.date-dropdown-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  padding-left: 3%;
  padding-right: 3%;
  cursor: pointer;
  &:hover {
    background-color: #f2f2f2;
  }
}
.border-top {
  border-top: 1.5px solid #ccc;
  justify-content: flex-start !important;
}
.border-top img {
  height: 18px;
  margin-right: 5px;
  margin-bottom: 4px;
}
.inputTextField {
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  background-color: transparent;
}

.modalReminder {
  transition: width 2s, height 2s, transform 2s;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Gilroy Regular";
  overflow: hidden;
  overflow-y: auto;
}
.ModalBodyReminder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-width: 20%;
  width: 33%;
  max-width: 60%;
  height: -moz-fit-content;
  height: fit-content;
  background-color: white;
  overflow: hidden;
  overflow-y: auto;
  border: none;
  border-radius: 8px;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 50px;
  padding-bottom: 45px;
}
.ModalBodyReminder h1 {
  font-size: 2.8rem;
  font-family: "Gilroy Semi-Bold";
  padding-bottom: 1.2vh;
}
.ModalBodyReminder h2 {
  font-size: 1.8rem;
  font-family: "Gilroy Semi-Bold";
  padding-top: 1.5vh;
  padding-bottom: 1vh;
}
.ModalBodyReminder h3 {
  font-size: 1.6rem;
  padding-bottom: 1vh;
}
.ModalBodyReminder h3 b {
  font-family: "Gilroy Semi-Bold";
  color: black;
}
.ModalBodyReminder textarea {
  width: 100%;
  min-height: 100px;
  padding: 2% 3%;
  font-size: 1.5rem;
  color: #4b4b4b; /* #999999; */
  border-color: #cecece;
  ::placeholder {
    font-size: 1.5rem;
    color: #999999;
  }
}
.ModalBodyReminder input {
  width: 0;
  height: 0;
  border: none;
  outline: none;
}
.ReminderCustomDateSelector {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #cecece;
  height: 40px;
  font-family: "Gilroy Light";
  font-size: 1.1rem;
  width: 13vw;
}
.ReminderCustomDateSelector img {
  height: 18px;
  width: 18px;
}
.reminder-date-show {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 5%;
  font-size: 1.5rem;
  color: #4b4b4b; /* #999999; */
  ::placeholder {
    font-size: 1.5rem;
    color: #999999;
  }
}
.reminder-date {
  min-width: 40px;
  width: 10%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.line-reminder {
  min-width: 0.7px;
  width: 0.7px;
  max-width: 0.7px;
  height: 100%;
  background: #cecece;
}
.ReminderActionButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 4vh;
  width: 100%;
}
.ReminderActionButtons button {
  /* min-width: 115px; */
  width: 50%;
  height: 40px;
  margin-right: 5px;
  color: white;
  background-color: #333333;
  border: none;
  /* border: 1px solid #333333; */
  padding-top: 3px;
  /* border-radius: 6px; */
  font-size: 1.6rem;
  &:hover {
    background-color: #555555;
    border-color: #555555;
    cursor: pointer;
  }
}
.ReminderLoader {
  width: 50%;
  height: 37px;
  margin-right: 5px;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.outline {
  border: 1.5px solid red;
}
.redError {
  background: red;
  border: 0.1px solid red;
}
.updateNote {
  margin-top: 1vh;
  color: white;
  background-color: #53dba7;
  padding-top: 0.4vh;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  padding-bottom: 0.4vh;
  border: none;
  border-radius: 6px;
  font-size: 1.3rem;
}
.closeConfirmRemoveButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.closeConfirmButtons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.disabledField {
  background-color: #e8e8e8;
  border-color: #333333;
}
.borderColorChange {
  background: #333333;
}
.RemoveButton {
  background-color: #fb3939 !important;
  min-width: 50px !important;
  width: 15% !important;
  max-width: 80px !important;
}
.RemoveButton img {
  width: 18px !important;
  height: 18px;
  margin: auto;
}
.confirmButton {
  background-color: #4256d0 !important;
  &:hover {
    background-color: #6776d4 !important;
    border-color: #555555;
    cursor: pointer;
  }
}

.sequences-dropdown2 {
  font-family: "Gilroy Regular";
  -webkit-user-select: none;
          user-select: none;
  /* font-size: 12px; */
  font-size: var(--body); /*1.4rem;*/
  position: relative;
  /* margin-left: 1%; */
  height: 100%;
  width: 100%;
  /* min-height: 20px; */
}
.SequencesDropdownMainContainer2 {
  font-family: "GIlroy Regular";
  /* font-size: 12px; */
  font-size: var(--body); /*1.4rem;*/
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid #c8c8c8; */
  /* padding-left: 3%; */
  background-color: white;
}
.SequencesDropdownMainContainerNotActive2 {
  cursor: not-allowed;
}
.SequencesDropdownMainContainerActive2 {
  &:hover {
    background-color: rgb(242, 241, 241);
    cursor: pointer;
  }
}
.whiteBackground2 {
  background-color: white;
}
.itemsContainer2 {
  padding-right: 3%;
  width: 100%;
  /* flex: 1; */
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.itemsContainer2 p {
  font-family: "Gilroy Semi-Bold";
  max-lines: 1;
  text-align: start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 3%;
}
.arrowsContainer2 {
  width: 15%;
  height: 100%;
  border-left: 3px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.arrowsContainer2 img {
  height: 6.15px;
}

.sequence-dropdown-menu3 {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 150px;
  border: 1px solid #ccc;
  background-color: #fff;
  overflow: hidden;
  overflow-y: auto;
  z-index: 9999909999999;
}
.sequence-dropdown-menu4 {
  position: absolute;
  bottom: 101%;
  left: 0;
  width: 100%;
  max-height: 150px;
  border: 1px solid #ccc;
  background-color: #fff;
  overflow: hidden;
  overflow-y: auto;
  z-index: 9999909999999;
}

.sequence-dropdown-item2 {
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #f2f2f2;
  }
}
.inputTextField2 {
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  background-color: transparent;
}

.modalReminder {
  transition: width 2s, height 2s, transform 2s;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Gilroy Regular";
  overflow: hidden;
  overflow-y: auto;
}
.ModalBodyReminder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-width: 20%;
  width: 33%;
  max-width: 60%;
  height: -moz-fit-content;
  height: fit-content;
  background-color: white;
  overflow: visible;
  /* overflow-y: auto; */
  border: none;
  border-radius: 8px;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 50px;
  padding-bottom: 45px;
}
.ModalBodyReminder h1 {
  font-size: 2.8rem;
  font-family: "Gilroy Semi-Bold";
  padding-bottom: 1.2vh;
}
.ModalBodyReminder h2 {
  font-size: 1.8rem;
  font-family: "Gilroy Semi-Bold";
  padding-top: 1.5vh;
  padding-bottom: 1vh;
}
.ModalBodyReminder h3 {
  font-size: 1.6rem;
  padding-bottom: 1vh;
}
.ModalBodyReminder h3 b {
  font-family: "Gilroy Semi-Bold";
  color: black;
}
.ReminderLoader {
  /* width: 117px; */
  height: 37px;
  margin-right: 5px;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.buttonsDivCategory {
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.buttonsDivCategory button {
  margin-left: 5px;
  margin-right: 5px;
  min-height: 20px;
  /* padding: 5px 15px; */
  padding-top: 5px;
  padding-bottom: 2px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
  font-size: 1.4rem;
  background-color: transparent;
  font-family: "Gilroy Semi-Bold";
  border: 2px solid #333;
  color: "black";
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.dropDownCOntainer {
  width: 100%;
  z-index: 9999;
  position: relative;
}

.file-upload {
  border: 2px dashed #ccc;
  text-align: center;
  cursor: pointer;
  transition: border 0.3s;
  font-family: "Gilroy Regular";
  display: flex;
  justify-content: center;
  align-items: center;
  height: 68px;
  cursor: pointer;
}
.mediaTab {
  background-color: #f5f5f5;
  border: dashed 2.5px #e0e0e0;
  width: 20vw;
  max-width: 400px;
  height: 8.5vh;
  max-height: 81px;
  &:hover {
    background-color: #eaeaea;
  }
}

.file-upload.dragging {
  border: 1px dashed #007bff;
}

.input {
  width: 100%;
  height: 100%;
  padding: 20%;
}

.documentDiv {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-left: 1vw;
  padding-right: 1vw;
  justify-content: space-between;
  align-items: center;
}
.documentDiv2 {
  width: 65%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}

.documentImage {
  width: 24px;
  height: 24px;
}

.documentText {
  font-size: 12px;
  text-align: center;
  margin-left: 2%;
}
.documentText2 {
  font-size: 12px;
}
.mediaTabDisplay {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: start;
  padding-left: 10px;
  padding-right: 10px;
}
.mediaTabDisplay img {
  width: 24px;
  height: 24px;
  margin-left: 20px;
}
.mediaTabDisplay div {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  font-family: "Gilroy Light";
}

.mediaTabDisplay text {
  font-size: 14px;
}
.mediaTabDisplay p {
  font-size: 10px;
}

.ModalBodyColorPicker {
  width: 25%;
  height: -moz-fit-content;
  height: fit-content;
  padding-top: 30px !important;
  padding-right: 25px !important;
  padding-left: 25px !important;
  padding-bottom: 25px !important;
}
.colorHeading {
  font-size: 2.5rem;
  padding-bottom: 2vh;
  font-family: "Gilroy Semi-Bold";
}
.colorPickerDiv {
  margin: auto;
  overflow: hidden;
  height: 100%;
}
.ColorPickerActionButtons {
  padding-left: 5%;
  padding-right: 5%;
}
.selected-color {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 1vh;
}
.selected-color p {
  font-family: "Gilroy Semi-Bold";
  font-size: 1.6rem;
  padding-right: 1rem;
}
.selected-color div {
  border-radius: 10px;
  width: 6vh;
  min-height: 35px;
  height: 3.5vh;
  max-height: 40px;
}
.swatches-picker {
  width: 100% !important;
  background: transparent !important ;
}
.swatches-picker div div {
  box-shadow: none !important;
}

.columns-container{
    border-top: 2px solid #c5c2c2;
    background-color: #f1f1f1;
    min-height: 1000px;
    height: 1000px;
    overflow-y: scroll;

}
.columns-page{
    width: 80%;
    margin: auto;
    padding: 100px;
    padding-bottom: 400px;

}
.check-list{
    list-style: none;
    padding: unset;
    margin: unset;
    padding-bottom: 30px;
}
.check-list-item{
    margin-left: 20px;
}
.check-list-item > label{
    margin-left: 20px;
    font-size: 12px;
}
.check-list-container{
    /* border-bottom: 1px solid white; */
}
.check-list-heading{
    border-bottom: 1px solid white;
    cursor: pointer;
    background-color: #586173;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 20px;
    color: white;
}
/* #root{
    overflow-y: auto;
}

.analytics-container{ 
    background: #EBECF0;
    height: 100vh;
}

.filters-section{
    height: 12vh;
    border-top: 2px solid rgb(227 227 227);
    font-weight: 500;

}
.filters-row{
    margin: auto;
    font-size: 16px;
    text-align: center;
}

.filters-drop-down{
    text-align: left;
    border: solid 1px #5c5c5cad;
    margin-left: 20px;
    margin-right: 20px;
    font-size: 15px;
    padding: 2px;
    font-family: 'SF Pro Display Semibold',
}

.analytics-section{
    height: -webkit-fill-available;
    background: #EBECF0;
}

.analytics-section .analytics-row-1{
    width: 100%;
    height: 40%;
    display: inline-block;
}

.analytics-section .analytics-row-2{
    margin-top: 2%;
    width: 100%;
    height: 60%;
}

.box-1{
    height: 100%;
    width: 50%;
    float: left;
}

.box-2{
    height: 100%;
    width: 50%;
    display: inline-block;
}

.box-3{
    height: 100%;
    width: 50%;
    display: inline-block;
}

.box-4{
    height: 100%;
    width: 50%;
    display: inline-block;
}

.box-1-chart{
    width: 90%;
    height: 80%;
    margin-left: 5%;
    margin-top: 5%;
}

.box-2-chart{
    width: 90%;
    height: 80%;
    margin-left: 5%;
    margin-top: 5%;
    box-shadow: 5px 5px 5px 5px #a9a5a5;
}

.box-3-chart{
    width: 90%;
    height: 80%;
    margin-left: 5%;
    margin-top: 1%;
    box-shadow: 5px 5px 5px 5px #a9a5a5;
}

.box-4-chart{
    width: 90%;
    height: 80%;
    margin-left: 5%;
    margin-top: 1%;
    box-shadow: 5px 5px 5px 5px #a9a5a5;
}

.stat-card{
    width: 46%;
    height: 46%;
    border: solid 1px black;
    border-radius: 5px;
    display: inline-block;
    margin-bottom: 4%;
    color: #FFFFFF;
    padding: 2%;
    font-size: 18px;
    font-weight: 700;
}

.card-1{
    background: #F5EDE2;
    color: #000000;
    padding: 2%;
    font-size: 18px;
}

.card-2{
    float: right;
    color: #000000;
    background: #F5EDE2;
}

.card-3{
    color: #000000;
    background: #F5EDE2;
}

.card-4{
    float: right;
    color: #000000;
    background: #F5EDE2;
}

.DateRangePicker{
    position: relative;
    display: inline-block;
    left: 0;
    top: 0;
}

.deleted-check-box,
.archived-check-box{
    margin-top: 10px!important;
    margin-right: 4%!important;
    display: inline-block;
} */
html, body, #root, .App {
    height: 100%;
}
#root{
    overflow-y: auto;
}

.analytics-container{
    background: #FAFAFA;
    height: -moz-fit-content;
    height: fit-content;
    font-family: "Inter-Regular";
}

.analytics-heading{
    font-size: 32px;
    font-weight: 600;
    line-height: 39px;
    letter-spacing: 0em;
    text-align: left;
    padding: 30px 0 25px 55px!important;
    color: #000000;
    background: #FAFAFA;
}

.filters-heading{
    font-style: normal;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    margin: 15px 55px!important;
    align-items: center;
    color: #333333;
}

.stats-note-heading{
    font-style: normal;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    margin: 15px 55px!important;
    align-items: center;
    color: red;
    padding-bottom: 25px;
}

.divider{
    height: 1px;
    background: #E0E0E0;
    margin: 0px 55px;
}

.stats-row{
    margin: 25px 55px;
}

.stats-box{
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
    border-radius: 5px; 
    width: -moz-fit-content; 
    width: fit-content;
    padding: 16px 25px!important;
    display: inline-block;
    margin-right: 4%;
}

.stats-heading{
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 10px!important;
}

.stats-numbers{
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    color: #4256D0;
}


.charts-row{
    width: 100%;
    height: 40%;
    display: inline-block;
}

.box-1{
    height: 100%;
    width: 50%;
    float: left;
}

.box-2{
    height: 100%;
    width: 50%;
    display: inline-block;
}

.box-1-chart{
    width: 90%;
    height: 90%;
    margin-left: 5%;
    margin-top: 5%;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
}

.box-2-chart{
    width: 90%;
    height: 90%;
    margin-left: 5%;
    margin-top: 5%;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 5%);
    border-radius: 5px;
    padding: 20px 0;
}

.DateRangePicker{
    position: relative;
    display: inline-block;
    left: 0;
    top: 0;
}

.deleted-check-box,
.archived-check-box{
    display: inline-block; 
    margin-right: 20px;
    margin-top: 12px!important;
} 

.select.filters-drop-down{
    box-sizing: border-box;
    height: 31px;
    background: #EDEFFF;
    border-radius: 200px;
    border: none;
    padding: 3px 12px;
    color: #4763E4;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    align-items: center;
    width: -moz-fit-content;
    width: fit-content;
    border-right: solid 10px transparent;
}

.emailModal {
  font-family: "Gilroy Semi-Bold";
  margin: auto;
  border-radius: 10;
  height: 100vh;
  background: rgba(39, 38, 38, 0.5);
  /* top: 60%; */
  padding-top: 10vh;
}
.headingEmailModal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 3vw;
  margin-right: 3vw;
  margin-top: 5vh;
}

.headingEmailModal h2 {
  font-family: "Gilroy Semi-Bold";
  font-size: 26px;
}
.headingEmailModal img {
  width: 32px;
  height: 32px;
  cursor: pointer;
}
.emailModalLine {
  margin-top: 2vh;
  height: 1px;
  width: 100%;
  background-color: #9f9f9f;
}
.emailTemplateModalBody {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-self: center;
  margin-left: 3vw;
  margin-right: 3vw;
  margin-top: 3vh;
  margin-bottom: 2vh;
}

.emailTemplateModalBody h3 {
  font-size: var(--subheading); /*16px;*/
  margin-bottom: 10px;
}

.emailTemplateModalBody input textarea {
  border: none;
  border: 1px solid #d4d4d4;
}
.emailTemplateModalBody input {
  margin-bottom: 10px;
  height: 40px;
  font-size: var(--body); /*14px;*/
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 2vh;
}

.emailTemplateModalBody textarea {
  min-height: 116px;
  font-size: var(--body); /*12px;*/
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.buttonsContainerModal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 3vw;
  margin-right: 3vw;
  /* margin-top: 1vh; */
  margin-bottom: 3vh;
}
.buttonsContainerModal button {
  width: 49%;
  height: 38px;
  color: white;
  font-size: 16px;
  border: none;
}
.cancelButton {
  background-color: #454545;
}
.sendButton {
  background-color: #4256d0;
}
.fileDivPDF {
  border: 2px solid #ff5722;
}
.fileDivIMG {
  border: 2px solid #4256d0;
}
.fileDiv {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  /* border: 1px solid #ff5722; */
  border-radius: 20px;
  padding: 3px;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 100%;
  margin-bottom: 1.5vh;
  background-color: #f8f9ff;
}
.fileDivElipsePDF {
  border: 1px solid #ff5722;
  background-color: #ff5722;
}
.fileDivElipseIMG {
  border: 1px solid #4256d0;
  background-color: #4256d0;
}
.fileDivElipse {
  height: 24px;
  width: 24px;
  min-height: 24px;
  min-width: 24px;
  /* border: 1px solid #ff5722; */
  border-radius: 100%;
  /* background-color: #ff5722; */
  color: white;
  font-size: 8px;
  padding-top: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1px;
}
.fileDivText p {
  /* max-width: 40%; */
  max-lines: 1;
  text-align: start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: var(--body3);
}
.fileDivText {
  width: -moz-fit-content;
  width: fit-content;
  /* max-width: 60%; */
  overflow: hidden;
  margin-left: 10px;
  margin-right: 10px;
}
.fileDiv img {
  width: 14.87px;
  height: 14.87px;
  margin-right: 5px;
  cursor: pointer;
}
.modalLoader {
  display: flex;
  flex-direction: row;
  width: 49%;
  height: 38px;
  justify-content: center;
  align-items: center;
}

.mediaTileMainContainer {
  font-family: "Gilroy Regular";
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #c6c6c6;
  /* min-height: 80px; */
  height: 9vh;
  max-height: 19vh;
  width: 100%;
  /* height: 80px; */
  padding-left: 5px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  position: relative;
  /* overflow: hidden; */
  &:hover {
    background-color: white;
    border: 2px solid #4256d0;
  }
}
.titlediv {
  width: 65%;
}
.pdfImage {
  width: 5.5vh;
  height: 5.5vh;
  max-width: 8vh;
  max-height: 8vh;
  /* max-width: 40px;
  max-height: 40px; */
}
.URLImage {
  width: 7.5vh;
  height: 7.5vh;
  max-width: 8vh;
  max-height: 8vh;
  &:hover {
    border: 1px solid transparent;
    border-radius: 6px;
    width: 28vh;
    height: 28vh;
    max-width: 28vh;
    max-height: 28vh;
    z-index: 2;
    transition: cubic-bezier(0.19, 1, 0.22, 1) 1s;
  }
}
.URLImageContainer {
  width: 7.5vh;
  height: 7.5vh;
  max-width: 8vh;
  max-height: 8vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.MediaTileDetails {
  margin-left: 15px;
  width: 100%;
  max-height: 110%;
  overflow: hidden;
}

.MediaTileDetails h3 {
  font-family: "Gilroy Regular";
  font-size: var(--body); /*1.2rem;*/
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.MediaTileDetails p {
  font-family: "Gilroy Regular";
  font-size: var(--body5); /*0.85rem;*/
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.buttonsForAction {
  position: absolute;
  right: 0;
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-right: 15px;
  background-color: white;
  height: 100%;
}
.buttonsForAction img {
  height: 18px;
  margin-left: 15px;
  cursor: pointer;
}
.nameLimit {
  width: 100%;
}
.my-popup-content {
  width: -moz-fit-content;
  width: fit-content;
  max-width: 75px;
  height: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
.my-popup-content img {
  margin-left: 0;
}

.dropdown-mhr {
  font-family: "Gilroy Regular";
  font-size: 14px;
  /* font-size: var(--body2); 1.2rem; */
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 150px;
  max-width: 300px;
  margin-left: 20px;
  /* background-color: #f6f6f6; */
  background-color: white;
  cursor: pointer;
}
.greyBackground {
  background-color: #f4f4f4;
  cursor: not-allowed;
}
.dropdown-mhr img {
  height: 6.15px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  transition: transform 0.3s ease;
}

.dropdown-button-mhr {
  padding-left: 10px;
  border: 1px solid #ccc;
  height: 41px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-right: 30px;
  &:hover {
    background-color: #f4f4f4;
  }
}
.dropdown-button-mhr-roundBorders {
  border-radius: 4px;
}

.dropdown-menu-mhr {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 190px;
  border: 1px solid #ccc;
  background-color: #fff;
  overflow: hidden;
  overflow-y: auto;
  z-index: 2;
}

.dropdown-item-mhr {
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #f2f2f2;
  }
}
.disable-sequence {
  background-color: #eeeeee;
}

.rootContainer {
  font-family: "Gilroy Regular";
  margin-top: 2vh;
  /* height: 60vh; */
  width: 100%;
}
.topBar {
  height: 38px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
}
.category {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: -20px;
}
.category div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 1.5vw;
}
.categoriesTextTotal {
  font-size: var(--heading2); /* 1.6rem; */
  cursor: pointer;
}
.categoriesTextTotalBold {
  font-family: "Gilroy Semi-Bold";
}
.categoriesTextBold {
  font-family: "Gilroy Semi-Bold";
  font-size: var(--subheading1); /* 1.5rem; */
  cursor: pointer;
}
.categoriesTextBold2 {
  font-family: "Gilroy Semi-Bold";
  font-size: var(--subheading1); /* 1.5rem; */
  margin-right: 1.5vw;
  cursor: pointer;
}
.categoriesText {
  font-size: var(--body); /* 1.2rem; */
  margin-right: 1.5vw;
  cursor: pointer;
}
.categoriesTextFirst {
  font-size: var(--body); /* 1.2rem; */
  cursor: pointer;
}
.searchInput {
  background-color: white;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #dcdcdc;
  padding-right: 0.5vh;
  padding-right: 0.5vh;
  height: 38px;
  width: 18.5vw;
  max-width: 345px;
}
.searchInput input {
  width: 17vw;
  font-family: "Gilroy Light";
  font-size: var(--body); /* 1.2rem; */
  margin-left: 0.5vw;
  border: none;
  outline: none;
  cursor: text;
}
.searchInput img {
  /* width: 19.3px; */
  height: var(--heading); /* 18px; */
  cursor: pointer;
  margin-right: 0.5vw;
  margin-left: 1vw;
}
.MediaTiles {
  display: grid;
  width: 100%;
  grid-column-gap: 0.5vw;
  column-gap: 0.5vw;
  /* row-gap: 3vh; */
  grid-row-gap: 3%;
  row-gap: 3%;
  /* row-gap: 0.5vw; */
  grid-template-columns: 24.5% 24.5% 24.5% 24.5%;
  grid-template-rows: 8.5vh 8.5vh 8.5vh 8.5vh;
  margin-top: 2vh;
  max-height: 57vh;
  height: 55vh;
}

.MediaTiles-item {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.8);
  padding: 20px;
  font-size: 3rem;
  text-align: center;
}

.MediaNavigation {
  margin-top: 3vh;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
}
.MediaNavigation img {
  height: var(--body); /* 12px; */
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
  &:hover {
    cursor: pointer;
    height: 16px;
  }
}
.navigationButton {
  border: none;
  /* outline: none; */
  margin-left: 5px;
  margin-right: 5px;
  height: 30px;
  width: 35px;
  background-color: white;
  padding-top: 5px;
  color: black;
  font-size: var(--body); /* 1.2rem; */
  &:hover {
    font-size: 1.6rem;
  }
}
.navigationButtonSelected {
  font-family: "Gilroy Semi-Bold";
  font-size: var(--subheading1); /* 1.4rem; */
  background-color: #4256d0;
  color: #f1f1f1;
}
.media-loader {
  margin-top: 2vh;
  max-height: 57vh;
  height: 55vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
}
.categoryOuterCOntainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.checkboxContainer {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  /* background-color: #f8f9ff; */
  border-style: solid none;
  border-width: 1px;
  border-color: #dcdcdc;
  &:hover {
    background-color: #fbfbfe;
  }
}
.borderWidthAndColor {
  background-color: #f8f9ff;
  border-width: 1.5px;
  border-color: #4256d0;
}
.defaultTemplateContainer {
  background-color: #effff9; /* #fff8e8; */
}
.mainTilesContainer {
  min-height: 97px;
  padding: 3%;
  align-items: center;
  font-family: Arial, Helvetica, sans-serif;
  overflow: hidden;
  width: 100%;
  cursor: pointer;
}
.spaceAndFlex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
}
.mhr-description {
  font-family: "Gilroy Regular";
  font-size: var(--body); /*12px;*/
}
.imageContainer {
  font-family: "Gilroy Semi-Bold";
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.imageContainer p {
  /* height: 100%; */
  font-size: var(--body2);
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.userImage {
  height: 18px;
  margin-right: 5px;
}
.borderContainer {
  font-family: "Gilroy Semi-Bold";
  border: 1.5px solid #c9c9c9;
  border-radius: 20px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 7px;
  padding-right: 7px;
  margin-right: 10px;
  font-size: var(--body); /*12px;*/
}
.copyImg {
  height: 26px;
  cursor: pointer;
}
.editDeleteContainer {
  font-family: "Gilroy Semi-Bold";
  display: flex;
  flex-direction: row;
  font-size: var(--body); /*12px;*/
  justify-content: center;
  align-items: start;
  padding-left: 20px;
}
.actionButtons {
  border: none;
  background-color: transparent;
  cursor: pointer;
  &:hover {
    color: #969696;
  }
}
.link {
  display: flex;
  flex-direction: row;
  width: -moz-fit-content;
  width: fit-content;
}
.link img {
  height: 14px;
}
.link p {
  font-family: "Gilroy Regular";
  padding-left: 5px;
  font-size: var(--body3); /*10px;*/
  &:hover {
    color: #969696;
  }
}
.checkboxContainer {
  padding-left: 1vw;
  padding-right: 1vw;
}
.rounded-checkbox {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid black;
  appearance: none;
  outline: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}
.rounded-checkbox:checked {
  -webkit-appearance: auto;
          appearance: auto;
  clip-path: circle(50% at 50% 50%);
  background-color: blue;
}
.mhr-title {
  font-family: "Gilroy Semi-Bold";
  font-size: var(--heading2); /*16px;*/
}
.defaultTemplate {
  display: flex;
  flex-direction: row;
  margin-right: 8px;
  align-items: end;
}
.defaultTemplate p {
  font-family: "Gilroy Semi-Bold";
  font-size: var(--body4); /*9px;*/
}
.defaultTemplate div {
  background-color: #22bc81; /*#ffc120;*/
  border: none;
  border-radius: 100%;
  width: 16px;
  height: 16px;
  margin-left: 5px;
  margin-right: 7px;
}
.checkButton-checked {
  width: 3rem;
  height: 3rem;
  background-color: #4256d0;
  border: 2px solid #15268f;
  border-radius: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
  overflow: hidden;
}
.checkButton-checked img {
  width: 2rem;
  height: 2remß;
}
.checkButton-unchecked {
  width: 3rem;
  height: 3rem;
  background-color: white;
  border: 2px solid #969696;
  border-radius: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
  overflow: hidden;
}
.actionButton2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1.5px solid;
  border-radius: 20px;
  border-color: #c9c9c9;
  cursor: pointer;
  background-color: white;
}
.actionButton2 div {
  margin-left: 20px;
  margin-right: 7px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #22bc81;
  border: none;
  border-radius: 100%;
  width: 1rem;
  height: 1rem;
}
.actionButton2 p {
  font-family: "Gilroy Regular";
  margin-right: 20px;
  font-size: var(--body2);
}

.detailsPart {
  border: 2px solid #dcdcdc;
  min-height: 54vh;
  max-height: 60vh;
  overflow: hidden;
  /* width: 100%; */
}
.actionButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #f6f7ff;
  padding-top: 5vh;
  padding-bottom: 5vh;
  border-bottom: 1.3px solid #e6e6e6;
  margin-bottom: 6vh;
}

.actionButton img {
  height: 2.9rem;
  cursor: pointer;
  margin-right: 0.4vw;
}

.actionButton div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1.5px solid;
  border-radius: 20px;
  border-color: #c9c9c9;
  cursor: pointer;
  background-color: white;
}

.actionButton div div {
  margin-left: 20px;
  margin-right: 7px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #22bc81; /*#ffc120;*/
  border: none;
  border-radius: 100%;
  width: 1rem;
  height: 1rem;
}
.actionButton div p {
  font-family: "Gilroy Regular";
  margin-right: 20px;
  font-size: var(--body2); /* 12px; */
}

.details {
  padding-left: 2vw;
  padding-right: 2vw;
  padding-bottom: 2vh;
  max-height: 42vh;
  overflow: hidden;
  overflow-y: auto;
}
.details::-webkit-scrollbar {
  width: 5px;
}
.details::-webkit-scrollbar-thumb {
  width: 3px;
  background-color: #969696;
  border-radius: 10px;
}

.details::-webkit-scrollbar-track {
  background-color: rgb(236, 236, 236);
}
.details div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  margin-top: 2vh;
  margin-bottom: 2vh;
}

.details div h5 {
  font-family: "Gilroy Semi-Bold";
  width: 70%;
  font-size: var(--heading2); /* 16px; */
}
.details div p {
  font-family: "Gilroy Light";
  width: 70%;
  color: #505050;
  font-size: var(--body); /* 12px; */
}
.details div p u {
  cursor: pointer;
}
.details div h3 img {
  height: 16px;
  margin-right: 6px;
}

.details div h6 {
  font-family: "Gilroy Regular";
  width: 30%;
  padding-left: 1vw;
  color: #505050;
  font-size: var(--subheading2); /* 12px; */
}
.creator {
  font-family: "Gilroy Regular";
  width: 70%;
  color: #505050;
  font-size: var(--body); /* 12px; */
}
.line {
  color: #e6e6e6;
  height: 0.08vh;
}
.noDetails {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.noDetails p {
  font-size: var(--body); /* 12px; */
}

.mainContainer {
  height: 94.5vh;
  padding-top: 2.5vh;
  /* padding-bottom: 40px; */
  padding-left: 15vw;
  padding-right: 15vw;
  /* background-color: white; */
  font-family: "Gilroy Regular";
  overflow: hidden;
  background-color: #f9f9f9;
}
.mainContainerHeaderClose {
  height: 99vh;
}
.headingContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 37px;
}
.containerHeader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.containerHeader h3 {
  font-family: "Gilroy Light";
  font-size: var(--mainheading2); /*22px;*/
}
.containerHeader img {
  height: var(--body); /*12px;*/
  margin-left: 10px;
  margin-bottom: 7px;
}
.containerHeader p {
  font-family: "Gilroy Semi-Bold";
  font-size: var(--mainheading); /*22px;*/
  margin-left: 10px;
}
.button {
  background-color: #4256d0;
  height: 37px;
  font-size: var(--body); /*12px;*/
  color: white;
  border: none;
  border-radius: 6px;
}
.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 1px;
  padding-bottom: 1px;
}
.buttonImage {
  height: 20px;
  padding-right: 10px;
}
.buttonImageSequence {
  height: 8px;
  padding-right: 10px;
}
.innerToMain {
  padding-left: 3px;
}
.flex {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin-top: 3vh;
}

.subheading {
  font-family: "Gilroy Light";
  font-size: var(--subheading1); /*14px;*/
}
.categories {
  /* padding-right: 1vh;
  padding-left: 1vh; */
  margin-right: 2vh;
  padding-bottom: 1vh;
  font-size: var(--subheading); /*16px;*/
  cursor: pointer;
  /* &:hover {
    font-size: var(--heading1); 17px;
  }  */
}
.bold {
  font-family: "Gilroy Semi-Bold";
  border-bottom: 3px solid #4256d0;
}
.innerToMain hr {
  padding-top: 0;
  margin-top: 0;
}
.dragDropContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
}
.templateLine {
  width: 100%;
  height: 1.5px;
  background-color: #d4d4d4;
}

.cursorDisabled {
  cursor: not-allowed;
}

.sequenceTileMainContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}
.tileLine {
  height: 1px;
  width: 100%;
  background-color: #d4d4d4;
  /* margin-bottom: 3vh; */
}
.innerContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  padding-left: 1vw;
  padding-right: 1vw;
  padding-bottom: 2vh;
  padding-top: 3vh;
  cursor: pointer;
  background-color: rgb(251, 251, 251);
  &:hover {
    /* background-color: #f8f9ff;
    border-top: 1px solid #4256d0;
    border-bottom: 1px solid #4256d0; */
    /* background-color: #f8f8f8; */
    background-color: white;
  }
}
.innerInnerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.innerContainer h6 {
  font-family: "Gilroy Semi-Bold";
  /* font-size: 16px; */
  font-size: var(--heading2); /*1.8rem;*/
  margin-right: 10px;
  /* margin-bottom: 2.4vh; */
}
.innerContainer img {
  /* height: 5.5vh;
  max-height: 51px; */
  height: 61px;
}
.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  overflow: hidden;
  padding-right: 1vw;
}
.textContainer h3 {
  font-family: "Gilroy Semi-Bold";
  /* font-size: 16px; */
  font-size: var(--heading2); /*1.8rem;*/
}
.textContainer h4 {
  font-family: "Gilroy Light";
  /* font-size: 12px; */
  font-size: var(--subheading2); /*1.5rem;*/
}
.textContainer h5 {
  font-family: "Gilroy Light";
  font-size: var(--body3); /*1.2rem;*/
  color: #505050;
}

.sequencesNav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.sequencesNavp {
  /* margin-right: 0.5vw; */
  /* font-size: 16px; */
  font-size: var(--heading2); /*1.8rem;*/
  font-family: "Gilroy Light";
  cursor: pointer;
}
.sequencesNavPBold {
  font-family: "Gilroy Semi-Bold";
}
.searchBar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid #d4d4d4;
  padding-left: 1vw;
  padding-right: 1vw;
  height: 58px;
  margin-top: 1vh;
  background-color: white;
  /* margin-bottom: 3vh; */
}
.searchBar input {
  border: none;
  outline: none;
  width: 100%;
  height: 15px;
  font-family: "Gilroy Light";
  color: #979696;
  /* font-size: 14px; */
  font-size: var(--subheading2); /*1.5rem;*/
  background-color: transparent;
  padding-right: 20px;
}
.searchBar img {
  height: 18px;
  cursor: pointer;
}
.sequencesTiles {
  height: 55vh;
  overflow: hidden;
  overflow-y: auto;
  padding-right: 5px;
}
.sequencesTiles::-webkit-scrollbar {
  width: 5px;
}
.sequencesTiles::-webkit-scrollbar-thumb {
  width: 3px;
  background-color: #969696;
  border-radius: 10px;
}

.sequencesTiles::-webkit-scrollbar-track {
  background-color: rgb(236, 236, 236);
}

.sequences-dropdown {
  font-family: "Gilroy Regular";
  -webkit-user-select: none;
          user-select: none;
  /* font-size: 12px; */
  font-size: var(--body); /*1.4rem;*/
  position: relative;
  margin-left: 1%;
  height: 100%;
  /* min-height: 20px; */
}
.SequencesDropdownMainContainer {
  font-family: "GIlroy Regular";
  /* font-size: 12px; */
  font-size: var(--body); /*1.4rem;*/
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #c8c8c8;
  padding-left: 3%;
  background-color: white;
}
.SequencesDropdownMainContainerNotActive {
  cursor: not-allowed;
}
.SequencesDropdownMainContainerActive {
  &:hover {
    background-color: rgb(242, 241, 241);
    cursor: pointer;
  }
}
.whiteBackground {
  background-color: white;
}
.itemsContainer {
  padding-right: 3%;
  width: 90%;
  overflow: hidden;
}
.itemsContainer p {
  font-family: "Gilroy Semi-Bold";
  max-lines: 1;
  text-align: start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.arrowsContainer {
  width: 15%;
  height: 100%;
  border-left: 1px solid #c8c8c8;
  display: flex;
  justify-content: center;
  align-items: center;
}
.arrowsContainer img {
  height: 6.15px;
}

.sequence-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 150px;
  border: 1px solid #ccc;
  background-color: #fff;
  overflow: hidden;
  overflow-y: auto;
  z-index: 1;
}
.sequence-dropdown-menu2 {
  position: absolute;
  bottom: 101%;
  left: 0;
  width: 100%;
  max-height: 150px;
  border: 1px solid #ccc;
  background-color: #fff;
  overflow: hidden;
  overflow-y: auto;
  z-index: 1;
}

.sequence-dropdown-item {
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #f2f2f2;
  }
}
.inputTextField {
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  background-color: transparent;
}

.sequence-modal-subheading {
  font-family: "Gilroy Semi-Bold";
  font-size: var(--subheading2); /*1rem;*/
  padding-bottom: 0.5vh;
}
.sequence-modal-title {
  font-family: "Gilroy Semi-Bold";
  font-size: var(--heading); /*1.8rem;*/
}
.sequence-modal-description {
  font-family: "Gilroy Regular";
  font-size: var(--subheading2); /*1.4rem;*/
}
.sequence-modal-line {
  background-color: #ccc;
  height: 1px;
  width: 100%;
  margin-top: 1vh;
  margin-bottom: 1vh;
}
.sequence-modal-line-upper {
  background-color: #ccc;
  height: 1px;
  width: 100%;
  margin-top: 1vh;
}
.sequence-modal-line-lower {
  background-color: #ccc;
  height: 1px;
  width: 100%;
  margin-bottom: 1vh;
}
.sequence-modal-inner-container {
  width: 100%;
  height: 85px;
  max-height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}
.sequence-modal-inner-inner-container1 {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}
.sequence-modal-vline {
  width: 1px;
  /* flex: 1; */
  height: 100%;
  background-color: #ccc;
}
.sequence-modal-inner-inner-container2 {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding-left: 1.5vw;
}
.sequence-inner-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  width: 85%;
}
.sequence-inner-container p {
  font-size: var(--body); /*1.4rem;*/
  max-lines: 1;
  text-align: start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sequence-inner-container img {
  height: 14px;
  margin-right: 5px;
}
.sequence-buttonsContainerModal {
  width: 30%;
  color: white;
  margin-left: 3vw;
  margin-right: 3vw;
  /* margin-top: 1vh; */
  margin-bottom: 3vh;
}
.sequence-close {
  background-color: #454545;
  width: 100%;
  height: 38px;
  color: white;
  font-family: "Gilroy Regular";
  font-size: 16px;
  border: none;
}

.rightNav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1vh;
}
.rightNav p {
  font-family: "Gilroy Semi-Bold";
  /* font-size: 18px; */
  font-size: var(--heading); /* 2rem; */
}
.rightDivInner {
  overflow: hidden;
  overflow-y: auto;
  height: 90%;
  padding-right: 2rem;
  padding-top: 1.5rem;
}
.rightDivInner::-webkit-scrollbar {
  width: 5px;
}
.rightDivInner::-webkit-scrollbar-thumb {
  width: 3px;
  background-color: #969696;
  border-radius: 30px;
}

.rightDivInner::-webkit-scrollbar-track {
  background-color: rgb(236, 236, 236);
  border-radius: 30px;
}
.actionButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  cursor: inherit;
  padding-right: 2rem;
}
.actionButtonsImg {
  margin-left: 0.3vw;
  height: 32px; /* 28.68px; */
  cursor: pointer;
}
.sequenceCancelButton {
  height: 36px;
  cursor: pointer;
  border: 2px solid black;
  border-radius: 100%;
  padding: 3.8%;
  margin-right: 0.25vw;
  &:hover {
    background-color: rgb(242, 241, 241);
  }
}
.saveSequenceContainer {
  height: 36px;
  width: max-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 2px solid #4256d0;
  border-radius: 20px;
  padding-left: 0.6vw;
  padding-right: 0.6vw;
  padding-top: 1vh;
  padding-bottom: 1vh;
  cursor: pointer;
  &:hover {
    background-color: #e7e9f7;
  }
}
.saveSequenceContainer img {
  height: 13px;
}
.saveSequenceContainer p {
  font-family: "Gilroy Semi-Bold";
  /* font-size: 12px; */
  font-size: var(--subheading2); /*1.5rem;*/
  color: #4256d0;
  padding-left: 0.3vw;
}
.sequenceDetails {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1.5vh;
}
.titleInput {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  outline: #4256d0;
}
.titleInputLabel {
  padding-bottom: 1vh;
  /* font-size: 14px; */
  font-size: var(--subheading1); /*1.6rem;*/
  font-family: "Gilroy Semi-Bold";
}
.titleInput input {
  border: none;
  border: 1px solid #c8c8c8;
  width: 100%;
  padding-left: 3%;
  /* font-size: 14px; */
  font-size: var(--subheading2); /* 1.5rem */
  height: 40px;
}
.titleInput textarea {
  border: none;
  border: 1px solid #c8c8c8;
  width: 100%;
  padding: 2% 3%;
  /* font-size: 14px; */
  font-size: var(--subheading2); /* 1.5rem */
  min-height: 80px;
  height: 7vh;
}
.typeDropDown {
  margin-left: 1vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.typeDropDown p {
  /* font-size: 14px; */
  font-size: var(--subheading1); /* 1.6rem */
  padding-bottom: 10px;
  font-family: "Gilroy Semi-Bold";
}
.drop {
  margin-left: -20px;
}
.sequenceSubheading {
  font-family: "Gilroy Semi-Bold";
  /* font-size: 18px; */
  font-size: var(--heading2); /* 1.8rem */
  padding-top: 1vh;
  padding-bottom: 1vh;
}
.sequencePattern {
  height: 100%;
  max-height: 66px;
  width: 60%;
  /* padding: 2%; */
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 2%;
  padding-right: 2%;
  display: flex;
  flex-direction: row;

  align-items: center;
  border-top: 1px solid #c8c8c8;
  border-bottom: 1px solid #c8c8c8;
  background-color: #eeeeee;
}
.sequencePatternStart {
  justify-content: flex-start;
}
.sequencePatternCenter {
  justify-content: center;
}
.viewDiv {
  height: 100%;
  padding: 3%;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin-right: 2%;
  cursor: pointer;
  &:hover {
    background-color: rgb(242, 241, 241);
  }
}
.viewDiv img {
  height: 16px;
}
.sequenceDropDown {
  width: 70%;
  height: 100%;
  margin-right: 2%;
}
.sequenceCount {
  font-family: "Gilroy Semi-Bold";
  /* font-size: 14px; */
  font-size: var(--body); /* 1.4rem */
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2%;
}
.startDivContainer {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.startDiv {
  width: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 4vh;
}
.startDiv p {
  font-family: "Gilroy Bold";
  /* font-size: 10px; */
  font-size: var(--body2); /* 1.2rem */
}
.ellipse1 {
  width: 6px;
  height: 6px;
  background-color: black;
  border: none;
  border-radius: 100%;
}
.line1 {
  width: 1.5px;
  flex: 1 1;
  background-color: black;
}
.line-div {
  width: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.middleDiv {
  width: 60%;
  height: 4.5vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.endDiv {
  width: 5%;
  height: 4vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.endDiv img {
  height: 6px;
  margin-top: -12%;
}
.endDiv p {
  font-family: "Gilroy Bold";
  font-size: var(--body2); /*1.2rem;*/
  /* font-size: 10px; */
}
.scheduleAfter {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding-right: 5%;
}
.scheduleLine {
  /* width: 10%; */
  flex: 0.4 1;
  height: 1px;
  background-color: #dcdcdc;
}
.scheduleText {
  font-family: "Gilroy Regular";
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: var(--body4); /*1.3rem;*/
}
.scheduleTextError {
  color: red;
}
.DaysCounter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: "Gilroy Semi-Bold";
}
.DaysCounter button {
  border: none;
  outline: none;
  font-size: 15px;
  /* font-size: 1.3rem; */
  width: 23px;
  height: 23px;
  margin-left: 0.3vw;
}
.DaysCounter p {
  font-family: "Gilroy Regular";
  /* font-size: 10px; */
  font-size: var(--body3); /*1.3rem;*/
  padding-left: 0.3vw;
}
.disabledInput {
  background-color: #eeeeee;
}

.sequencesMainContainer {
  width: 100%;
  height: 69vh;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  font-family: "Gilroy Regular";
  /* padding-bottom: 3vh; */
}
.line {
  width: 1px;
  height: 100%;
  background-color: #d4d4d4;
}
.leftDiv {
  width: 44%;
  margin-top: 20px;
  padding-right: 3vw;
  height: 100%;
}
.rightDiv {
  width: 56%;
  padding-top: 20px;
  padding-left: 3vw;
  overflow: hidden;
  /* background-color: #f9f9f9; */
  /* overflow-y: auto; */
}
.loaderDiv {
  display: flex;
  height: 60vh;
  justify-content: center;
  align-items: center;
}
.loaderDiv1 {
  display: flex;
  height: 40vh;
  justify-content: center;
  align-items: center;
  /* font-size: 12px; */
  font-size: 1rem;
}

.modalSpotlight {
  background: transparent !important;
}
.modalBodySpotlight {
  width: 45vw !important;
  padding: 0 !important;
  margin: 0 !important;
  padding-left: 25px !important;
  padding-right: 35px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  margin-bottom: 10% !important;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: #404040;
  border-radius: 9px !important;
  z-index: 9999 !important;
  opacity: 0.97 !important;
}

.modalBodySpotlight img {
  width: 20px;
  height: 20px;
  margin-right: 15px;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}

.spotlightInput {
  width: 100% !important;
  height: 50px !important;
  background-color: transparent;
  color: white;
  font-size: 1.8rem;
  font-family: "Gilroy Regular";
  -webkit-user-select: none;
          user-select: none;
  &:focus {
    color: white;
  }
  &::placeholder {
    color: #9e9e9e;
  }
}

.modalBodySpotlight p {
  font-size: 1.2rem;
  color: white;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}

.tooltip-containerr {
	position: relative;
	display: inline-block;
}

.tooltip-boxx {
	visibility: hidden;
	background-color: #0e1b6b; /* Tooltip background */
	color: #fff; /* Tooltip text color */
	text-align: center;
	border-radius: 6px;
	padding: 8px 10px;
	position: absolute;
	top: 125%; /* Position the tooltip below the element */
	left: 50%;
	transform: translateX(-50%);
	z-index: 1;
	white-space: nowrap;
}

.tooltip-containerr:hover .tooltip-boxx {
	visibility: visible;
}

.tooltip-arroww {
	position: absolute;
	bottom: 100%; /* Position the arrow on the top edge */
	left: 50%;
	transform: translateX(-50%);
	border-width: 5px;
	border-style: solid;
	border-color: transparent transparent #0e1b6b transparent;
}

.tooltip-textt {
	font-size: 10px;
}

.harvest-action-button {
	padding: 0;
	margin-left: 5px;
	margin-right: 5px;
	min-height: 20px;
	padding-top: 5px;
	padding-bottom: 4px;
	padding-left: 15px;
	padding-right: 15px;
	border-radius: 5px;
	font-size: 1.4rem;
	background-color: transparent;
	font-family: "Gilroy Semi-Bold";
	border: 2px solid #333;
	color: "black";
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
.havrestSubheading {
	margin-bottom: 40px;
	font-size: 1.5rem;
}
.harvestModalBody {
	width: 45%;
}
.customHarvestPopup {
	padding: 30px;
	padding-top: 40px;
	padding-bottom: 25px;
}
.customHarvestPopup h1 {
	font-size: 2.2rem;
}
.customHarvestPopup span {
	font-size: 1.5rem;
}
.customHarvestPopup button {
	font-size: 1.6rem;
}
.harvestModalBody h1 {
	font-size: 2.8rem;
}
.harvestModalBody span {
	font-size: 1.5rem;
}
.harvestModalBody button {
	font-size: 1.6rem;
}

.App {
  padding-top: 0px;
}
