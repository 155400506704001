@font-face {
  font-family: "Gilroy Light";
  /* font-weight: 900; */
  src: url("../src/gilroy/Gilroy-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy Regular";
  /* font-weight: 900; */
  src: url("../src/gilroy/Gilroy-Regular.ttf") format("truetype");
  font-weight: 400;
  /* font-style: normal; */
}
@font-face {
  font-family: "Gilroy Semi-Bold";
  /* font-weight: 900; */
  src: url("../src/gilroy/Gilroy-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy Bold";
  /* font-weight: 900; */
  src: url("../src/gilroy/Gilroy-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy Bolder";
  /* font-weight: 900; */
  src: url("../src/gilroy/Gilroy-Heavy.ttf") format("truetype");
}
