/* #root{
    overflow-y: auto;
}

.analytics-container{ 
    background: #EBECF0;
    height: 100vh;
}

.filters-section{
    height: 12vh;
    border-top: 2px solid rgb(227 227 227);
    font-weight: 500;

}
.filters-row{
    margin: auto;
    font-size: 16px;
    text-align: center;
}

.filters-drop-down{
    text-align: left;
    border: solid 1px #5c5c5cad;
    margin-left: 20px;
    margin-right: 20px;
    font-size: 15px;
    padding: 2px;
    font-family: 'SF Pro Display Semibold',
}

.analytics-section{
    height: -webkit-fill-available;
    background: #EBECF0;
}

.analytics-section .analytics-row-1{
    width: 100%;
    height: 40%;
    display: inline-block;
}

.analytics-section .analytics-row-2{
    margin-top: 2%;
    width: 100%;
    height: 60%;
}

.box-1{
    height: 100%;
    width: 50%;
    float: left;
}

.box-2{
    height: 100%;
    width: 50%;
    display: inline-block;
}

.box-3{
    height: 100%;
    width: 50%;
    display: inline-block;
}

.box-4{
    height: 100%;
    width: 50%;
    display: inline-block;
}

.box-1-chart{
    width: 90%;
    height: 80%;
    margin-left: 5%;
    margin-top: 5%;
}

.box-2-chart{
    width: 90%;
    height: 80%;
    margin-left: 5%;
    margin-top: 5%;
    box-shadow: 5px 5px 5px 5px #a9a5a5;
}

.box-3-chart{
    width: 90%;
    height: 80%;
    margin-left: 5%;
    margin-top: 1%;
    box-shadow: 5px 5px 5px 5px #a9a5a5;
}

.box-4-chart{
    width: 90%;
    height: 80%;
    margin-left: 5%;
    margin-top: 1%;
    box-shadow: 5px 5px 5px 5px #a9a5a5;
}

.stat-card{
    width: 46%;
    height: 46%;
    border: solid 1px black;
    border-radius: 5px;
    display: inline-block;
    margin-bottom: 4%;
    color: #FFFFFF;
    padding: 2%;
    font-size: 18px;
    font-weight: 700;
}

.card-1{
    background: #F5EDE2;
    color: #000000;
    padding: 2%;
    font-size: 18px;
}

.card-2{
    float: right;
    color: #000000;
    background: #F5EDE2;
}

.card-3{
    color: #000000;
    background: #F5EDE2;
}

.card-4{
    float: right;
    color: #000000;
    background: #F5EDE2;
}

.DateRangePicker{
    position: relative;
    display: inline-block;
    left: 0;
    top: 0;
}

.deleted-check-box,
.archived-check-box{
    margin-top: 10px!important;
    margin-right: 4%!important;
    display: inline-block;
} */
html, body, #root, .App {
    height: 100%;
}
#root{
    overflow-y: auto;
}

.analytics-container{
    background: #FAFAFA;
    height: fit-content;
    font-family: "Inter-Regular";
}

.analytics-heading{
    font-size: 32px;
    font-weight: 600;
    line-height: 39px;
    letter-spacing: 0em;
    text-align: left;
    padding: 30px 0 25px 55px!important;
    color: #000000;
    background: #FAFAFA;
}

.filters-heading{
    font-style: normal;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    margin: 15px 55px!important;
    align-items: center;
    color: #333333;
}

.stats-note-heading{
    font-style: normal;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    margin: 15px 55px!important;
    align-items: center;
    color: red;
    padding-bottom: 25px;
}

.divider{
    height: 1px;
    background: #E0E0E0;
    margin: 0px 55px;
}

.stats-row{
    margin: 25px 55px;
}

.stats-box{
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
    border-radius: 5px; 
    width: fit-content;
    padding: 16px 25px!important;
    display: inline-block;
    margin-right: 4%;
}

.stats-heading{
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 10px!important;
}

.stats-numbers{
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    color: #4256D0;
}


.charts-row{
    width: 100%;
    height: 40%;
    display: inline-block;
}

.box-1{
    height: 100%;
    width: 50%;
    float: left;
}

.box-2{
    height: 100%;
    width: 50%;
    display: inline-block;
}

.box-1-chart{
    width: 90%;
    height: 90%;
    margin-left: 5%;
    margin-top: 5%;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
}

.box-2-chart{
    width: 90%;
    height: 90%;
    margin-left: 5%;
    margin-top: 5%;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 5%);
    border-radius: 5px;
    padding: 20px 0;
}

.DateRangePicker{
    position: relative;
    display: inline-block;
    left: 0;
    top: 0;
}

.deleted-check-box,
.archived-check-box{
    display: inline-block; 
    margin-right: 20px;
    margin-top: 12px!important;
} 

.select.filters-drop-down{
    box-sizing: border-box;
    height: 31px;
    background: #EDEFFF;
    border-radius: 200px;
    border: none;
    padding: 3px 12px;
    color: #4763E4;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    align-items: center;
    width: fit-content;
    border-right: solid 10px transparent;
}
