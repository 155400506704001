.rightNav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1vh;
}
.rightNav p {
  font-family: "Gilroy Semi-Bold";
  /* font-size: 18px; */
  font-size: var(--heading); /* 2rem; */
}
.rightDivInner {
  overflow: hidden;
  overflow-y: auto;
  height: 90%;
  padding-right: 2rem;
  padding-top: 1.5rem;
}
.rightDivInner::-webkit-scrollbar {
  width: 5px;
}
.rightDivInner::-webkit-scrollbar-thumb {
  width: 3px;
  background-color: #969696;
  border-radius: 30px;
}

.rightDivInner::-webkit-scrollbar-track {
  background-color: rgb(236, 236, 236);
  border-radius: 30px;
}
.actionButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  cursor: inherit;
  padding-right: 2rem;
}
.actionButtonsImg {
  margin-left: 0.3vw;
  height: 32px; /* 28.68px; */
  cursor: pointer;
}
.sequenceCancelButton {
  height: 36px;
  cursor: pointer;
  border: 2px solid black;
  border-radius: 100%;
  padding: 3.8%;
  margin-right: 0.25vw;
  &:hover {
    background-color: rgb(242, 241, 241);
  }
}
.saveSequenceContainer {
  height: 36px;
  width: max-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 2px solid #4256d0;
  border-radius: 20px;
  padding-left: 0.6vw;
  padding-right: 0.6vw;
  padding-top: 1vh;
  padding-bottom: 1vh;
  cursor: pointer;
  &:hover {
    background-color: #e7e9f7;
  }
}
.saveSequenceContainer img {
  height: 13px;
}
.saveSequenceContainer p {
  font-family: "Gilroy Semi-Bold";
  /* font-size: 12px; */
  font-size: var(--subheading2); /*1.5rem;*/
  color: #4256d0;
  padding-left: 0.3vw;
}
.sequenceDetails {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1.5vh;
}
.titleInput {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  outline: #4256d0;
}
.titleInputLabel {
  padding-bottom: 1vh;
  /* font-size: 14px; */
  font-size: var(--subheading1); /*1.6rem;*/
  font-family: "Gilroy Semi-Bold";
}
.titleInput input {
  border: none;
  border: 1px solid #c8c8c8;
  width: 100%;
  padding-left: 3%;
  /* font-size: 14px; */
  font-size: var(--subheading2); /* 1.5rem */
  height: 40px;
}
.titleInput textarea {
  border: none;
  border: 1px solid #c8c8c8;
  width: 100%;
  padding: 2% 3%;
  /* font-size: 14px; */
  font-size: var(--subheading2); /* 1.5rem */
  min-height: 80px;
  height: 7vh;
}
.typeDropDown {
  margin-left: 1vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.typeDropDown p {
  /* font-size: 14px; */
  font-size: var(--subheading1); /* 1.6rem */
  padding-bottom: 10px;
  font-family: "Gilroy Semi-Bold";
}
.drop {
  margin-left: -20px;
}
.sequenceSubheading {
  font-family: "Gilroy Semi-Bold";
  /* font-size: 18px; */
  font-size: var(--heading2); /* 1.8rem */
  padding-top: 1vh;
  padding-bottom: 1vh;
}
.sequencePattern {
  height: 100%;
  max-height: 66px;
  width: 60%;
  /* padding: 2%; */
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 2%;
  padding-right: 2%;
  display: flex;
  flex-direction: row;

  align-items: center;
  border-top: 1px solid #c8c8c8;
  border-bottom: 1px solid #c8c8c8;
  background-color: #eeeeee;
}
.sequencePatternStart {
  justify-content: flex-start;
}
.sequencePatternCenter {
  justify-content: center;
}
.viewDiv {
  height: 100%;
  padding: 3%;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin-right: 2%;
  cursor: pointer;
  &:hover {
    background-color: rgb(242, 241, 241);
  }
}
.viewDiv img {
  height: 16px;
}
.sequenceDropDown {
  width: 70%;
  height: 100%;
  margin-right: 2%;
}
.sequenceCount {
  font-family: "Gilroy Semi-Bold";
  /* font-size: 14px; */
  font-size: var(--body); /* 1.4rem */
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2%;
}
.startDivContainer {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.startDiv {
  width: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 4vh;
}
.startDiv p {
  font-family: "Gilroy Bold";
  /* font-size: 10px; */
  font-size: var(--body2); /* 1.2rem */
}
.ellipse1 {
  width: 6px;
  height: 6px;
  background-color: black;
  border: none;
  border-radius: 100%;
}
.line1 {
  width: 1.5px;
  flex: 1;
  background-color: black;
}
.line-div {
  width: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.middleDiv {
  width: 60%;
  height: 4.5vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.endDiv {
  width: 5%;
  height: 4vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.endDiv img {
  height: 6px;
  margin-top: -12%;
}
.endDiv p {
  font-family: "Gilroy Bold";
  font-size: var(--body2); /*1.2rem;*/
  /* font-size: 10px; */
}
.scheduleAfter {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding-right: 5%;
}
.scheduleLine {
  /* width: 10%; */
  flex: 0.4;
  height: 1px;
  background-color: #dcdcdc;
}
.scheduleText {
  font-family: "Gilroy Regular";
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: var(--body4); /*1.3rem;*/
}
.scheduleTextError {
  color: red;
}
.DaysCounter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: "Gilroy Semi-Bold";
}
.DaysCounter button {
  border: none;
  outline: none;
  font-size: 15px;
  /* font-size: 1.3rem; */
  width: 23px;
  height: 23px;
  margin-left: 0.3vw;
}
.DaysCounter p {
  font-family: "Gilroy Regular";
  /* font-size: 10px; */
  font-size: var(--body3); /*1.3rem;*/
  padding-left: 0.3vw;
}
.disabledInput {
  background-color: #eeeeee;
}
