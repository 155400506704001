
/**Inter Regular Font */
@font-face {
	font-family: 'Inter-Regular';
	src: url(../src/inter/Inter-Regular.ttf);
}

@font-face {
	font-family: 'Inter-Black';
	src: url(../src/inter/Inter-Black.ttf);
}

