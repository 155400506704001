.ModalBodyColorPicker {
  width: 25%;
  height: fit-content;
  padding-top: 30px !important;
  padding-right: 25px !important;
  padding-left: 25px !important;
  padding-bottom: 25px !important;
}
.colorHeading {
  font-size: 2.5rem;
  padding-bottom: 2vh;
  font-family: "Gilroy Semi-Bold";
}
.colorPickerDiv {
  margin: auto;
  overflow: hidden;
  height: 100%;
}
.ColorPickerActionButtons {
  padding-left: 5%;
  padding-right: 5%;
}
.selected-color {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 1vh;
}
.selected-color p {
  font-family: "Gilroy Semi-Bold";
  font-size: 1.6rem;
  padding-right: 1rem;
}
.selected-color div {
  border-radius: 10px;
  width: 6vh;
  min-height: 35px;
  height: 3.5vh;
  max-height: 40px;
}
.swatches-picker {
  width: 100% !important;
  background: transparent !important ;
}
.swatches-picker div div {
  box-shadow: none !important;
}
