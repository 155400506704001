.sequencesMainContainer {
  width: 100%;
  height: 69vh;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  font-family: "Gilroy Regular";
  /* padding-bottom: 3vh; */
}
.line {
  width: 1px;
  height: 100%;
  background-color: #d4d4d4;
}
.leftDiv {
  width: 44%;
  margin-top: 20px;
  padding-right: 3vw;
  height: 100%;
}
.rightDiv {
  width: 56%;
  padding-top: 20px;
  padding-left: 3vw;
  overflow: hidden;
  /* background-color: #f9f9f9; */
  /* overflow-y: auto; */
}
.loaderDiv {
  display: flex;
  height: 60vh;
  justify-content: center;
  align-items: center;
}
.loaderDiv1 {
  display: flex;
  height: 40vh;
  justify-content: center;
  align-items: center;
  /* font-size: 12px; */
  font-size: 1rem;
}
