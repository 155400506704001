.modalReminder {
  transition: width 2s, height 2s, transform 2s;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Gilroy Regular";
  overflow: hidden;
  overflow-y: auto;
}
.ModalBodyReminder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-width: 20%;
  width: 33%;
  max-width: 60%;
  height: fit-content;
  background-color: white;
  overflow: visible;
  /* overflow-y: auto; */
  border: none;
  border-radius: 8px;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 50px;
  padding-bottom: 45px;
}
.ModalBodyReminder h1 {
  font-size: 2.8rem;
  font-family: "Gilroy Semi-Bold";
  padding-bottom: 1.2vh;
}
.ModalBodyReminder h2 {
  font-size: 1.8rem;
  font-family: "Gilroy Semi-Bold";
  padding-top: 1.5vh;
  padding-bottom: 1vh;
}
.ModalBodyReminder h3 {
  font-size: 1.6rem;
  padding-bottom: 1vh;
}
.ModalBodyReminder h3 b {
  font-family: "Gilroy Semi-Bold";
  color: black;
}
.ReminderLoader {
  /* width: 117px; */
  height: 37px;
  margin-right: 5px;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.buttonsDivCategory {
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.buttonsDivCategory button {
  margin-left: 5px;
  margin-right: 5px;
  min-height: 20px;
  /* padding: 5px 15px; */
  padding-top: 5px;
  padding-bottom: 2px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
  font-size: 1.4rem;
  background-color: transparent;
  font-family: "Gilroy Semi-Bold";
  border: 2px solid #333;
  color: "black";
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.dropDownCOntainer {
  width: 100%;
  z-index: 9999;
  position: relative;
}
