.mediaTileMainContainer {
  font-family: "Gilroy Regular";
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #c6c6c6;
  /* min-height: 80px; */
  height: 9vh;
  max-height: 19vh;
  width: 100%;
  /* height: 80px; */
  padding-left: 5px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  position: relative;
  /* overflow: hidden; */
  &:hover {
    background-color: white;
    border: 2px solid #4256d0;
  }
}
.titlediv {
  width: 65%;
}
.pdfImage {
  width: 5.5vh;
  height: 5.5vh;
  max-width: 8vh;
  max-height: 8vh;
  /* max-width: 40px;
  max-height: 40px; */
}
.URLImage {
  width: 7.5vh;
  height: 7.5vh;
  max-width: 8vh;
  max-height: 8vh;
  &:hover {
    border: 1px solid transparent;
    border-radius: 6px;
    width: 28vh;
    height: 28vh;
    max-width: 28vh;
    max-height: 28vh;
    z-index: 2;
    transition: cubic-bezier(0.19, 1, 0.22, 1) 1s;
  }
}
.URLImageContainer {
  width: 7.5vh;
  height: 7.5vh;
  max-width: 8vh;
  max-height: 8vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.MediaTileDetails {
  margin-left: 15px;
  width: 100%;
  max-height: 110%;
  overflow: hidden;
}

.MediaTileDetails h3 {
  font-family: "Gilroy Regular";
  font-size: var(--body); /*1.2rem;*/
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.MediaTileDetails p {
  font-family: "Gilroy Regular";
  font-size: var(--body5); /*0.85rem;*/
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.buttonsForAction {
  position: absolute;
  right: 0;
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-right: 15px;
  background-color: white;
  height: 100%;
}
.buttonsForAction img {
  height: 18px;
  margin-left: 15px;
  cursor: pointer;
}
.nameLimit {
  width: 100%;
}
.my-popup-content {
  width: fit-content;
  max-width: 75px;
  height: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
.my-popup-content img {
  margin-left: 0;
}
