/* ========================== */
.modall {
  transition: width 2s, height 2s, transform 2s;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Gilroy Regular";
  overflow: hidden;
}
.ModalBody {
  min-width: 30%;
  width: min-content;
  max-width: 80%;
  height: 80%;
  background-color: white;
  overflow: hidden;
  overflow-y: auto;
}
.ModalBody2 {
  width: 65%;
}
.ModalBody3 {
  width: 80%;
}

/* ========================== */

.SequencePauseDetailsModalBody {
  margin-top: 3vh;
  padding-bottom: 3vh;
  margin-left: 3vw;
  margin-right: 3vw;
}

.SequencePauseDetailsModalBody hr {
  width: 100%;
  height: 1px;
  background-color: black;
}

.TileAndDateOuterContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.TileAndDateOuterContainer p {
  font-size: 1.4rem;
}
.TileAndDateOuterContainer h3 {
  padding-top: 4px;
  font-size: 2.4rem;
  white-space: nowrap;
}
.TitleContainerSequenceDetails {
  width: 65%;
}
.DateContainerSequenceDetails {
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.TempalateDetailsOuterContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.TemplateOneDetailsContainer {
  width: 100%;
}
.TemplateTwoDetailsContainer {
  width: 50%;
}
.TemplateThreeDetailsContainer {
  width: 33.33%;
}
.TemplateOneDetailsContainer h2 {
  font-size: 20px;
  color: #007d0d;
  font-family: "Gilroy Semi-Bold";
}
.scheduleContainer div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}
.scheduleContainer div img {
  margin-left: 8px;
  height: 16px;
}
.scheduleContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  min-height: 50px;
  height: 50px;
  max-height: 50px;
  color: black;
}
.scheduleContainer h3 {
  font-size: 1.6rem;
  font-family: "Gilroy Semi-Bold";
  color: black;
}
.scheduleContainer p {
  font-size: 1.4rem;
  font-family: "Gilroy Semi-Bold";
  color: #626262;
}
.StatusContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1vh;
}
.StatusContainer img {
  width: 30px;
  height: 30px;
}
.TickEclipse {
  width: 21px;
  height: 20px;
  background-color: #d9d9d9;
  border: 1px solid #d9d9d9;
  border-radius: 100%;
}
.statusLine1 {
  background-color: #d9d9d9;
  width: 100%;
  height: 2px;
}
.green {
  background-color: #00e917;
}
.TemplateDetailsContainerOuter {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
}
.TemplateDetailsContainerOuter h2 {
  font-size: 1.7rem;
  color: black;
  font-family: "Gilroy Semi-Bold";
}
.TemplateDetailsContainerTitles {
  width: 20%;
  font-size: 1.5rem;
  font-family: "Gilroy Semi-Bold";
}
.TemplateDetailsContainerDescriptions {
  width: 80%;
  font-size: 1.5rem;
  line-height: 16px;
}
.TemplateDetailsContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
}
.DetailsContainerMHR {
  overflow: hidden;
  overflow-y: auto;
  padding-left: 1vw;
  padding-right: 1vw;
  height: 30vh;
  margin-top: 5vh;
}
/* .totalTemplates2 { */
/* margin-top: 1vh; */
/* } */
.totalTemplates3 {
  margin-top: 3vh;
}
.actionButtonS {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  /* padding-bottom: 5vh; */
}

.actionButtonS button {
  border: none;
  outline: none;
  font-family: "Gilroy Semi-Bold";
  font-size: 17px;
  color: white;
  height: 38px;
  width: 195px;
  margin-top: 4vh;
}
.actionButtonS button div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  /* padding-bottom: 5vh; */
}

.actionButton1 {
  background-color: #454545;
  margin-left: 10px;
}
.actionButton3 {
  background-color: #f2bd00;
  margin-left: 10px;
}
.loaderonbutton {
  height: 38px;
  width: 195px;
  margin-top: 4vh;
  /* background-color: #f2bd00; */
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
