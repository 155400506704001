.sequences-dropdown {
  font-family: "Gilroy Regular";
  user-select: none;
  /* font-size: 12px; */
  font-size: var(--body); /*1.4rem;*/
  position: relative;
  margin-left: 1%;
  height: 100%;
  /* min-height: 20px; */
}
.SequencesDropdownMainContainer {
  font-family: "GIlroy Regular";
  /* font-size: 12px; */
  font-size: var(--body); /*1.4rem;*/
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #c8c8c8;
  padding-left: 3%;
  background-color: white;
}
.SequencesDropdownMainContainerNotActive {
  cursor: not-allowed;
}
.SequencesDropdownMainContainerActive {
  &:hover {
    background-color: rgb(242, 241, 241);
    cursor: pointer;
  }
}
.whiteBackground {
  background-color: white;
}
.itemsContainer {
  padding-right: 3%;
  width: 90%;
  overflow: hidden;
}
.itemsContainer p {
  font-family: "Gilroy Semi-Bold";
  max-lines: 1;
  text-align: start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.arrowsContainer {
  width: 15%;
  height: 100%;
  border-left: 1px solid #c8c8c8;
  display: flex;
  justify-content: center;
  align-items: center;
}
.arrowsContainer img {
  height: 6.15px;
}

.sequence-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 150px;
  border: 1px solid #ccc;
  background-color: #fff;
  overflow: hidden;
  overflow-y: auto;
  z-index: 1;
}
.sequence-dropdown-menu2 {
  position: absolute;
  bottom: 101%;
  left: 0;
  width: 100%;
  max-height: 150px;
  border: 1px solid #ccc;
  background-color: #fff;
  overflow: hidden;
  overflow-y: auto;
  z-index: 1;
}

.sequence-dropdown-item {
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #f2f2f2;
  }
}
.inputTextField {
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  background-color: transparent;
}
