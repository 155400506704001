.emailModal1111 {
  font-family: "Gilroy Semi-Bold";
  margin: auto;
  border-radius: 10;
  min-height: 100vh;
  height: 100vh;
  max-height: 100vh;
  background: rgba(39, 38, 38, 0.5);
  /* top: 60%; */
  padding-top: 10vh;
}
.headingEmailModal1111 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 3vw;
  margin-right: 3vw;
  margin-top: 5vh;
}
/* .inner-outer-div {
  max-height: 80vh;
  overflow: hidden;
  overflow-y: auto;
} */
.headingEmailModal h2 {
  font-family: "Gilroy Semi-Bold";
  font-size: 2.8rem !important;
}
.headingEmailModal img {
  width: 32px;
  height: 32px;
  cursor: pointer;
}
.emailModalLine {
  margin-top: 2vh;
  height: 1px;
  width: 100%;
  background-color: #9f9f9f;
}

.buttonsContainerModal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 3vw;
  margin-right: 3vw;
  /* margin-top: 1vh; */
  margin-bottom: 3vh;
}
.buttonsContainerModal button {
  width: 49%;
  height: 38px;
  color: white;
  font-size: 1.6rem !important;
  border: none;
}
.cancelButton {
  background-color: #454545;
}
.sendButton {
  background-color: #4256d0;
}
.modalLoader {
  display: flex;
  flex-direction: row;
  width: 49%;
  height: 38px;
  justify-content: center;
  align-items: center;
}

/* =================== */

.SequenceStartModalBody {
  margin-top: 3vh;
  margin-bottom: 6vh;
  margin-left: 3vw;
  margin-right: 3vw;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
}
.SequenceStartModalBody p {
  font-family: "Gilroy Regular";
  font-size: 1.6rem;
}
.SequenceStartModalBody-sh {
  font-family: "Gilroy semi-bold" !important;
  font-size: 1.8rem !important;
}
.SequenceStartModalBody input {
  /* width: 100%;
  height: 40px;
  padding: 20px;
  font-family: "Gilroy Light";
  font-size: 12px;
  border: none;
  border: 1px solid #ccc; */
  width: 0;
  height: 0;
  border: none;
  outline: none;
}
.viewSequenceDetails {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  color: #4256d0;
  border: none;
  border: 1px solid #4256d0;
  padding: 1% 3%;
  visibility: hidden;
  &:hover {
    cursor: pointer;
    background-color: #e7e7e7;
  }
}
.viewSequenceDetails-visible {
  visibility: visible;
}

.viewSequenceDetails img {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
.viewSequenceDetails p {
  font-size: 1.3rem;
}
.seuqneceLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 20vh;
}

.ReminderCustomDateSelector1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #cecece;
  height: 40px;
  font-family: "Gilroy Light";
  /* font-size: 1.1rem; */
  width: 100%;
}
.ReminderCustomDateSelector1 img {
  height: 24px;
  width: 24px;
}
.reminder-date-show1 {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 5%;
}
.reminder-date-show1 p {
  /* font-size: 1.3rem; */
  color: #454545;
}
.reminder-date1 {
  min-width: 40px;
  width: 10%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
