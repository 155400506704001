.mainContainer {
  height: 94.5vh;
  padding-top: 2.5vh;
  /* padding-bottom: 40px; */
  padding-left: 15vw;
  padding-right: 15vw;
  /* background-color: white; */
  font-family: "Gilroy Regular";
  overflow: hidden;
  background-color: #f9f9f9;
}
.mainContainerHeaderClose {
  height: 99vh;
}
.headingContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 37px;
}
.containerHeader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.containerHeader h3 {
  font-family: "Gilroy Light";
  font-size: var(--mainheading2); /*22px;*/
}
.containerHeader img {
  height: var(--body); /*12px;*/
  margin-left: 10px;
  margin-bottom: 7px;
}
.containerHeader p {
  font-family: "Gilroy Semi-Bold";
  font-size: var(--mainheading); /*22px;*/
  margin-left: 10px;
}
.button {
  background-color: #4256d0;
  height: 37px;
  font-size: var(--body); /*12px;*/
  color: white;
  border: none;
  border-radius: 6px;
}
.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 1px;
  padding-bottom: 1px;
}
.buttonImage {
  height: 20px;
  padding-right: 10px;
}
.buttonImageSequence {
  height: 8px;
  padding-right: 10px;
}
.innerToMain {
  padding-left: 3px;
}
.flex {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin-top: 3vh;
}

.subheading {
  font-family: "Gilroy Light";
  font-size: var(--subheading1); /*14px;*/
}
.categories {
  /* padding-right: 1vh;
  padding-left: 1vh; */
  margin-right: 2vh;
  padding-bottom: 1vh;
  font-size: var(--subheading); /*16px;*/
  cursor: pointer;
  /* &:hover {
    font-size: var(--heading1); 17px;
  }  */
}
.bold {
  font-family: "Gilroy Semi-Bold";
  border-bottom: 3px solid #4256d0;
}
.innerToMain hr {
  padding-top: 0;
  margin-top: 0;
}
.dragDropContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
}
.templateLine {
  width: 100%;
  height: 1.5px;
  background-color: #d4d4d4;
}

.cursorDisabled {
  cursor: not-allowed;
}
