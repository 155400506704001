.file-upload {
  border: 2px dashed #ccc;
  text-align: center;
  cursor: pointer;
  transition: border 0.3s;
  font-family: "Gilroy Regular";
  display: flex;
  justify-content: center;
  align-items: center;
  height: 68px;
  cursor: pointer;
}
.mediaTab {
  background-color: #f5f5f5;
  border: dashed 2.5px #e0e0e0;
  width: 20vw;
  max-width: 400px;
  height: 8.5vh;
  max-height: 81px;
  &:hover {
    background-color: #eaeaea;
  }
}

.file-upload.dragging {
  border: 1px dashed #007bff;
}

.input {
  width: 100%;
  height: 100%;
  padding: 20%;
}

.documentDiv {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-left: 1vw;
  padding-right: 1vw;
  justify-content: space-between;
  align-items: center;
}
.documentDiv2 {
  width: 65%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}

.documentImage {
  width: 24px;
  height: 24px;
}

.documentText {
  font-size: 12px;
  text-align: center;
  margin-left: 2%;
}
.documentText2 {
  font-size: 12px;
}
.mediaTabDisplay {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: start;
  padding-left: 10px;
  padding-right: 10px;
}
.mediaTabDisplay img {
  width: 24px;
  height: 24px;
  margin-left: 20px;
}
.mediaTabDisplay div {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  font-family: "Gilroy Light";
}

.mediaTabDisplay text {
  font-size: 14px;
}
.mediaTabDisplay p {
  font-size: 10px;
}
