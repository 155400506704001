.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.tabElement {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 168px;
  height: 100%;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  /* font-weight: 400; */
  font-family: "SF Pro Display Semibold";
}
.ExportElement {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33%;
  height: 100%;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  /* font-weight: 400; */
  font-family: "SF Pro Display Semibold";
}
.ExportElement > button {
  border: none;
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: contain;
  cursor: pointer;
  width: 45px;
  height: 19px;
  /* align-self: center; */
  margin-right: 5px;
}
.ExportElement > span {
  font-size: 14px;
  /* font-weight: 300; */
  /* margin-left: 5px; */
}
.ExportElement:hover {
  color: #0a84fe;
}

.active-tab {
  text-align: center;
  border-top: 4px solid #4256d0;
  color: black;
  /* font-weight: 400; */
  font-family: "SF Pro Display Semibold";
}
.completed-tab {
  border-top: 4px solid #00ff22;
}
.aging-tab {
  border-top: 4px solid #ff0000;
}
.inprogress-tab {
  border-top: 4px solid #ffa600;
}
.fresh-tab {
  border-top: 4px solid #0004ff;
}
.-header {
  color: #ebe7e7;
}
.tabElement > button {
  border: none;
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: contain;
  cursor: pointer;
  width: 45px;
  height: 19px;
  /* align-self: center; */
  margin-right: 5px;
}
.tabElement > span {
  font-size: 14px;
  color: black;
  /* font-weight: 300; */
  /* margin-left: 5px; */
}
.tabElement:hover {
  color: #0a84fe;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* generic classes */
.flex-row-center {
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
}
.flex-row-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.flex-row-space {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: center;
}
.modal-footer-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 20px;
}
.draggable-header {
  text-transform: uppercase;
  color: #e6e6eb;
}
.attach-file-save-button {
  outline: none;
  background: #333;
  border: none;
  display: inline-block;
  padding: 5px 24px;
  color: #eee;
  margin-right: 12px;
  border-radius: 5px;
  font-size: 13px;
  width: 20%;
  cursor: pointer;
}

.note-edit-modal-save-button {
  outline: none;
  background: #333;
  border: none;
  display: inline-block;
  padding: 5px 24px;
  color: #eee;
  margin-right: 12px;
  border-radius: 5px;
  font-size: 13px;
  cursor: pointer;
}
.temp-modal-save-button {
  outline: none;
  background: #333;
  border: none;
  display: inline-block;
  padding: 5px 24px;
  color: #eee;
  /* margin-right: 12px; */
  border-radius: 5px;
  font-size: 13px;
  cursor: pointer;
}
.note-modal-save-button {
  outline: none;
  background: #333;
  border: none;
  display: inline-block;
  padding: 5px 24px;
  color: #eee;
  border-radius: 5px;
  font-size: 13px;
  cursor: pointer;
}
.temp-card-save-button {
  outline: none;
  background: #333;
  border: none;
  display: flex;
  padding: 5px 24px;
  color: #eee;
  border-radius: 5px;
  font-size: 13px;
  cursor: pointer;
}

.temp-card-button-cont {
  gap: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 82%;
}
.edit-modal-save-button {
  outline: none;
  background: #333;
  border: none;
  display: inline-block;
  padding: 6px 18px;
  color: #eee;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}
.edit-modal-cancel-button {
  outline: none;
  background: #333;
  border: none;
  display: inline-block;
  padding: 6px 18px;
  color: #eee;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}
.delete-button-container {
  display: "flex";
  align-self: "flex-end";
  background-color: "green";
  padding: 6px 18px;
}
.modal-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
/* .modal-backdrop {
opacity: 0.5 !important;
} */

/* .modal-backdrop {
background: #ffffffe6 !important;
opacity: 0.5 !important;
}

.fade.modal-backdrop.newBackdrop.show {
opacity: 0.8;
} */

.search-bar:focus {
  outline: 2px solid #0a84fe;
  background: #ffffff;
  border: 2px solid #0a84fe;
  box-shadow: -2px 3px 8px 1px rgba(182, 169, 152, 0.3);
  border-radius: 5px;
}

.tooltip-text {
  visibility: hidden;
  width: 100px;
  background-color: #0e1b6b;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
}

.tooltip-text::after {
  content: " ";
  position: absolute;
  bottom: 100%; /* At the top of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.tooltip-text.users-tooltip {
  bottom: -35%;
  left: 33%;
  transform: translate(-50%, 0);
}

.tooltip-text.add-lead-tooltip {
  bottom: -35%;
  left: 20%;
  transform: translate(-50%, 0);
}

.tooltip-text.leads-source-tooltip {
  bottom: -35%;
  left: 8%;
  transform: translate(-50%, 0);
}

.tooltip-text.form-tooltip {
  bottom: -35%;
  left: 45%;
  transform: translate(-50%, 0);
}

.users-icon:hover .tooltip-text.users-tooltip {
  visibility: visible;
}

.leads-icon:hover .tooltip-text.add-lead-tooltip {
  visibility: visible;
}

.leads-source-icon:hover .tooltip-text.leads-source-tooltip {
  visibility: visible;
}

.form-icon:hover .tooltip-text.form-tooltip {
  visibility: visible;
}

.notes-container {
  padding: 10px 40px;
  margin-top: 10;
  overflow-y: scroll;
  height: 50vh;
  /* background-color: #fafaff; */
}
.time-line-conatiner {
  border: 0px 0px 0px 1px;
  border-color: #4256d0;
  border-style: solid;
  position: relative;
  margin-left: 8;
  background-color: #fafaff;
}
.note-info-title {
  padding: 10px;
  font-size: 12px;
}

.followup-modal-save-button {
  outline: none;
  background: #333;

  /* background: #0a84fe; */
  border: none;
  display: inline-block;
  padding: 5px 24px;
  color: #eee;
  border-radius: 5px;
  font-size: 13px;
  cursor: pointer;
  width: 10wv;
  /* position: absolute; */
  right: 40px;
}
.add-temp-button-container {
  display: flex;
  justify-content: flex-end;
  flex: 1;
}
.note-divider {
  width: 100%;
  height: 1px;
  background-color: lightgray;
}
.note-info-text-value {
  font-weight: 600;
  font-size: 15px;
  text-align: center;
  padding-top: 3px;
}
.note-input-container {
  margin-top: 0px;
  padding: 0px 35px;
  overflow-y: hidden;
}
.note-info-container {
  display: flex;
  flex-direction: row;
  padding: 5px 35px;
  /* width: 40vw; */
}
.note-info-sub-container {
  display: flex;
  flex-direction: row;
  /* flex: 1; */
  justify-content: flex-start;
  margin-right: 30px;
  align-items: center;
}

.note-input {
  color: black;
  font-size: 14;
  width: 100%;
  border-style: solid;
  border-width: 1px;
  padding: 8px;
  margin: 0px 0px;
  border-color: grey;
  border-radius: 5px;
  height: 50px;
}
.note-lead-name-container {
  font-size: 13px;
  display: flex;
  flex-direction: row;
  padding: 5px 35px;
}
.modal-heading-cont {
  margin: 0px;
  padding: 10px 35px 0px 35px;
  width: 100%;
}
.modal-heading {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  padding: 0px;
  margin: 0px;
  text-align: left;
  font-weight: 600;
  font-size: 24px;
}

.child-modal-container {
  margin-top: 10;
  width: 30vw;
  height: 20vh;
}
.notes-loader {
  position: relative;
  height: 50vh;
  justify-content: center;
  align-self: center;
}
.custom-ui {
  z-index: 1000;
}
.custom-select {
  position: relative;
  display: inline-block;
}

.custom-select select {
  display: none;
}

.custom-select::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #555;
}

.custom-select select,
.custom-select::after {
  cursor: pointer;
}

.custom-select select:focus {
  outline: none;
}
.followup-modal-footer {
  justify-content: space-between;
  display: flex;
  margin: 40px 0px 0px 0px;
  width: 100%;
}
.followup-modal-footer {
  justify-content: space-between;
  display: flex;
  /* margin: 40px 0px 0px 0px; */
  width: 100%;
}
.followup-modal-body-review-normal {
  border: none;
  color: black;
  border-color: transparent;
  border-radius: 3px;
}
.followup-modal-body-review-referral {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 2px solid gray;
  border-radius: 10px;
  font-size: 18px;
  padding-left: 2.5vw;
  padding-right: 2.5vw;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
  margin-left: 0.7vw;
  margin-right: 0.7vw;
  cursor: pointer;
  position: relative;
}
.followup-modal-body-review-referral-selected {
  border-color: #53dba7;
}
.followup-modal-body-review-referral-check {
  position: absolute;
  max-height: 20px;
  max-width: 20px;
  top: -7px;
  right: -14px;
}
.followup-modal-body-review-referral img {
  height: 36px;
  /* width: 32px; */
  margin-right: 5px;
}
.followup-modal-body {
  margin-top: 15px;
  /* row-gap: 20px; */
  display: flex;
  flex-direction: column;
}
.followup-modal-loader-body {
  justify-content: center;
  align-items: center;
  display: flex;
  /* margin: 40px 0px 0px 0px; */
  width: 100%;
  height: 20vh;
}
.followup-form-control {
  width: 450px;
  height: 40px;
  margin: 20px 0px;
}
.followup-note-lead-name-container {
  /* width: 50vw; */
  font-size: 14px;
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  /* padding: 20px 20px; */
}
.tester-label {
  /* width: 50vw; */
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-left: 4px;
  align-items: center;

  /* margin-top: 15px; */
  /* padding: 20px 20px; */
}
.select-text-title {
  margin-top: 10px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: 600;
  font-size: 14px;
}
.followup-modal-heading {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  padding: 0px;
  margin: 0px;
  text-align: left;
  font-weight: 500;
  font-size: 18px;
  width: fit-content;
  cursor: pointer;
  margin-right: 10px;
  border: 2px solid #ccc;
  border-radius: 6px;
  color: gray;
  padding-left: 0.7vw;
  padding-right: 0.7vw;
  padding-top: 0.8vh;
  padding-bottom: 0.8vh;
  position: relative;
}
.followup-modal-heading-img1 {
  position: absolute;
  width: 15px;
  height: 15px;
  top: -8px;
  right: 10%;
  left: 90%;
}
.followup-modal-heading-img2 {
  position: absolute;
  width: 18px;
  height: 18px;
  top: -8.5px;
  right: 18%;
  left: 82%;
}
.selected-followup-modal-heading {
  border-color: #4256d0;
  color: #4256d0;
}
.normal-followup-modal-heading {
  border: none;
  color: black;
  margin: 0;
  padding: 0;
  font-size: 22px;
  font-weight: 500;
}
.followup-sub-heading {
  font-size: 14px;
  font-weight: 600;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  padding-bottom: 3px;
}
.followup-select-option {
  width: 400px;
  padding: 5px 0px 5px 10px;
}
.add-temp-input {
  width: 100%;
  border-color: lightgrey;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  height: 6vh;
  font-size: 1.4em;
  margin-bottom: 10px;
  padding: 5px 10px;
}
.add-temp-modal-textarea {
  height: 80px;
  padding: 10px;
  width: 100%;
  border-color: lightgrey;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  font-size: 1.4em;
}

.add-temp-modal-body {
  padding: 10px 20px;
  width: 100%;
}
.ReactTable .rt-thead .rt-tr {
  width: 100%;
  justify-content: flex-start;
}
.ReactTable .rt-tbody {
  overflow: hidden; /* scroll; */
  /* padding-left: 30px; */
  /* width: 100%; */

  align-items: flex-start;
}
.option-add-temp-cont {
  width: "100%";
  display: flex;
  flex-direction: row;
}
.add-temp-note-button-text {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 5px;
}
.add-temp-button {
  font-size: 17px;
  height: 20px;
  width: 20px;
  border-radius: 50px;
  border-color: grey;
  color: grey;
  text-align: center;
  border-style: solid;
  border-width: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.temp-del-text {
  color: crimson;
}
textarea::placeholder {
  color: #282c34;
}
.template-textarea {
  height: 80px;
  padding: 10px;
  border-radius: 5px;
  font-size: 13px;
  color: #282c34;
}
.refRev-checkbox-container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.refreview-modal {
  width: 70vw;
}

.refRev-tester-container {
  width: 50%;
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 0px 0px 10px;
  margin-right: -20px;
}
.test-receiver-textarea {
  margin-top: 5px;
  /* height: 80px; */
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  font-size: 13px;
  border-style: solid;
  border-width: 1px;
  border-color: lightgrey;
  color: #282c34;
}
.MuiButtonBase-root {
  background-color: #00ff22;
}
.text-area-container {
  display: flex;
  flex: 1;
  width: "100%";
  flex-direction: column;
  margin-top: 30;
}
.call-rail-select-cont {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.all-rail-select-form {
  background-color: #00ff22;
  width: 100%;
  height: 6vh;
}
.call-rail-source-value {
  align-self: center;
  color: black;
  font-size: 13px;
  line-height: 1.1;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 10vw;
}
.heading-medium {
  padding: 10px 0px;
}
.template-screen-body {
  padding: 60px;
  display: flex;
  flex-direction: column;
}
.temp-card-inner-cont {
  display: flex;
  flex-direction: row;
  /* column-gap: 10px; */
  overflow-x: scroll;
  /* width: 98vw; */
}
.temp-card-container {
  padding: 0px 0px 20px 50px;
  margin: 10px;
  width: 90%;
  /* border-color: #ececf3;
border-width: 1px;
border-style: solid;
border-radius: 5px; */
  /* background-color: #61dafb; */
  /* width: 85vw; */
}
.sub-heading-cont {
  /* padding: 0px 40px 0px 0px; */
  margin: 20px 0px 10px 0px;
  /* position: relative; */
  /* background-color: #e6e6eb; */
}
.template-screen-loader-body {
  height: 70vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.temp-card-cont {
  height: fit-content;
  width: 22vw;
  border-width: 1px;
  border-color: lightgrey;
  border-style: solid;
  padding: 0px;
}
.filter-select {
  height: 4vh;
  border-radius: 5px;
  margin-left: 3px;
  font-size: 13px;
}

.temp-card-cont-1 {
  align-items: center;
  height: fit-content;
  width: 30%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  padding: 0px;
}

.temp-card-filter-cont {
  height: 3vh;
  width: 30%;
  display: flex;
  justify-content: flex-end;
  padding: 0px;
}
.temp-card-cont-2 {
  height: fit-content;
  width: 35%;
  height: fit-content;
  display: flex;
  justify-content: flex-end;
  padding: 0px;
}
.template-attachment {
  padding: 0px 0px 0px 0px;
  white-space: nowrap;
  width: 20vw;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.card-checkbox-container {
  width: 7vw;
  position: relative;
  display: flex;
  flex-direction: row;
}
.heading-cont {
  width: 60%;
}
.heading-cont-refModal {
  width: 80%;
}
.test-checkbox-container {
  width: 40%;
  /* width: 15vw; */
  position: relative;
  display: flex;
  flex-direction: row;
  /* align-items: flex-end; */
  justify-content: flex-end;
}
.template-card-body {
  height: 18vh;
  /* width: 250vw; */
  padding: 5px 5px 0px 5px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.slick-next {
  color: red !important; /* Set the desired color */
  background-color: transparent; /* Set the desired background color */
}
.slick-prev {
  font-size: 60px;
}
.slick-prev:before,
.slick-next:before {
  height: 30px;
  width: 30px;
  color: gray !important;
}
.loader-cont {
  display: flex;
  flex-direction: row;
}
/* .slider {
  width: 96vw;
margin-left: 20px;
padding-left: 10px;
} */
.action-cont {
  border-top: lightgray;
  border-top-width: 1px;
  border-top-style: solid;
  padding: 0px;
  margin: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.action-button-cont {
  flex-direction: row;
  display: "flex";
  justify-content: "space-between";
  background-color: #ffa600;
  width: "100%";
}
.attachment-cont {
  padding: 5px 10px 5px 25px;
}

.card-action-button {
  height: 3vh;
}
.line {
  width: 100%;
  height: 1px;
  background-color: lightgray;
}
/* .MuiSelect-root ;
background-color: #00ff22;
} */
textarea::-webkit-input-placeholder {
  color: grey;
}
.ql-container {
  display: flex;
  flex-direction: column-reverse;
  padding: 0px;
  /* flex: 1; */
  /* background-color: #0004ff; */
  /* height: 5vh; */
  overflow: hidden;
  border: none;
}
.typo-nowrap {
  white-space: nowrap;
  width: 12vw;
  padding-left: 2px;
  overflow: hidden;
  text-overflow: ellipsis;

  /* style={{ fontSize: 13, paddingLeft: 2, overflow: "hidden", textOverflow: "ellipsis" }} */
}

.ql-toolbar {
  order: 2;
  margin: 0px;
  /* width: 900px; */
}

.ql-editor {
  order: 1;
  margin: 0px;
}
.display-linebreak {
  white-space: pre-line;
}
.upload-text {
  position: "absolute";
  background-color: #555;
  z-index: 1;
  left: 0;
  top: 0;
}
.ReactTable .checkbox-input1 {
  z-index: 999 !important;
  /* height: 30rem; */
}
.custom-header .rt-thead .rt-th,
.custom-header .rt-thead .rt-td {
  /* height: 36px !important; */
  padding: 7px 8px !important;
  background-color: #00ff22 !important;
}
.invalidEmailIcon {
  width: 24px;
  height: 24px;
  margin-right: 5px;
  &:hover {
    cursor: pointer;
    background-color: #f1f1f1;
    border-radius: 100%;
  }
}
