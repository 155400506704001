.sequencesNav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.sequencesNavp {
  /* margin-right: 0.5vw; */
  /* font-size: 16px; */
  font-size: var(--heading2); /*1.8rem;*/
  font-family: "Gilroy Light";
  cursor: pointer;
}
.sequencesNavPBold {
  font-family: "Gilroy Semi-Bold";
}
.searchBar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid #d4d4d4;
  padding-left: 1vw;
  padding-right: 1vw;
  height: 58px;
  margin-top: 1vh;
  background-color: white;
  /* margin-bottom: 3vh; */
}
.searchBar input {
  border: none;
  outline: none;
  width: 100%;
  height: 15px;
  font-family: "Gilroy Light";
  color: #979696;
  /* font-size: 14px; */
  font-size: var(--subheading2); /*1.5rem;*/
  background-color: transparent;
  padding-right: 20px;
}
.searchBar img {
  height: 18px;
  cursor: pointer;
}
.sequencesTiles {
  height: 55vh;
  overflow: hidden;
  overflow-y: auto;
  padding-right: 5px;
}
.sequencesTiles::-webkit-scrollbar {
  width: 5px;
}
.sequencesTiles::-webkit-scrollbar-thumb {
  width: 3px;
  background-color: #969696;
  border-radius: 10px;
}

.sequencesTiles::-webkit-scrollbar-track {
  background-color: rgb(236, 236, 236);
}
