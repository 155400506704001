.modalReminder {
  transition: width 2s, height 2s, transform 2s;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Gilroy Regular";
  overflow: hidden;
  overflow-y: auto;
}
.ModalBodyReminder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-width: 20%;
  width: 33%;
  max-width: 60%;
  height: fit-content;
  background-color: white;
  overflow: hidden;
  overflow-y: auto;
  border: none;
  border-radius: 8px;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 50px;
  padding-bottom: 45px;
}
.ModalBodyReminder h1 {
  font-size: 2.8rem;
  font-family: "Gilroy Semi-Bold";
  padding-bottom: 1.2vh;
}
.ModalBodyReminder h2 {
  font-size: 1.8rem;
  font-family: "Gilroy Semi-Bold";
  padding-top: 1.5vh;
  padding-bottom: 1vh;
}
.ModalBodyReminder h3 {
  font-size: 1.6rem;
  padding-bottom: 1vh;
}
.ModalBodyReminder h3 b {
  font-family: "Gilroy Semi-Bold";
  color: black;
}
.ModalBodyReminder textarea {
  width: 100%;
  min-height: 100px;
  padding: 2% 3%;
  font-size: 1.5rem;
  color: #4b4b4b; /* #999999; */
  border-color: #cecece;
  ::placeholder {
    font-size: 1.5rem;
    color: #999999;
  }
}
.ModalBodyReminder input {
  width: 0;
  height: 0;
  border: none;
  outline: none;
}
.ReminderCustomDateSelector {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #cecece;
  height: 40px;
  font-family: "Gilroy Light";
  font-size: 1.1rem;
  width: 13vw;
}
.ReminderCustomDateSelector img {
  height: 18px;
  width: 18px;
}
.reminder-date-show {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 5%;
  font-size: 1.5rem;
  color: #4b4b4b; /* #999999; */
  ::placeholder {
    font-size: 1.5rem;
    color: #999999;
  }
}
.reminder-date {
  min-width: 40px;
  width: 10%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.line-reminder {
  min-width: 0.7px;
  width: 0.7px;
  max-width: 0.7px;
  height: 100%;
  background: #cecece;
}
.ReminderActionButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 4vh;
  width: 100%;
}
.ReminderActionButtons button {
  /* min-width: 115px; */
  width: 50%;
  height: 40px;
  margin-right: 5px;
  color: white;
  background-color: #333333;
  border: none;
  /* border: 1px solid #333333; */
  padding-top: 3px;
  /* border-radius: 6px; */
  font-size: 1.6rem;
  &:hover {
    background-color: #555555;
    border-color: #555555;
    cursor: pointer;
  }
}
.ReminderLoader {
  width: 50%;
  height: 37px;
  margin-right: 5px;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.outline {
  border: 1.5px solid red;
}
.redError {
  background: red;
  border: 0.1px solid red;
}
.updateNote {
  margin-top: 1vh;
  color: white;
  background-color: #53dba7;
  padding-top: 0.4vh;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  padding-bottom: 0.4vh;
  border: none;
  border-radius: 6px;
  font-size: 1.3rem;
}
.closeConfirmRemoveButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.closeConfirmButtons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.disabledField {
  background-color: #e8e8e8;
  border-color: #333333;
}
.borderColorChange {
  background: #333333;
}
.RemoveButton {
  background-color: #fb3939 !important;
  min-width: 50px !important;
  width: 15% !important;
  max-width: 80px !important;
}
.RemoveButton img {
  width: 18px !important;
  height: 18px;
  margin: auto;
}
.confirmButton {
  background-color: #4256d0 !important;
  &:hover {
    background-color: #6776d4 !important;
    border-color: #555555;
    cursor: pointer;
  }
}
