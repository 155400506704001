.nav-tabs .nav-link.active {
	background-color: #007bff !important; /* Change this to your desired color */
	color: white !important; /* Ensure the text is readable */
	border-color: #007bff !important; /* Match the border color */
}

.placeholder-color::-webkit-input-placeholder {
	color: lightgray;
}
.custom-modal-header .close {
	font-size: 2rem; /* Adjust the size as needed */
}
.modalContainer {
	border-radius: 5px;
	background-color: white;
	position: fixed;
	top: 500%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-height: 90vh;
	max-width: 90vw;
	width: 35vw; /* Adjust based on your default preferred width */
	height: 39vh; /* Adjust based on your default preferred height */
	overflow-y: auto;
	overflow-x: hidden;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 20px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.modalContainer1 {
	border-radius: 5px;
	background-color: white;
	/* position: fixed; */
	top: 500%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-height: 90vh;
	max-width: 90vw;
	width: 35vw; /* Adjust based on your default preferred width */
	height: 50vh; /* Adjust based on your default preferred height */
	overflow-y: auto;
	overflow-x: hidden;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 20px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Adjustments for smaller screens */
@media (max-width: 1200px) {
	.modalContainer {
		width: 30vw;
		height: 40vh;
	}
}

@media (max-width: 992px) {
	.modalContainer {
		width: 40vw;
		height: 45vh;
	}
}

@media (max-width: 768px) {
	.modalContainer {
		width: 50vw;
		height: 50vh;
	}
}

@media (max-width: 576px) {
	.modalContainer {
		width: 70vw;
		height: 60vh;
	}
}

@media (max-width: 400px) {
	.modalContainer {
		width: 90vw;
		height: 70vh;
	}
}
